// Must be before everything
import 'react-native-gesture-handler';

import { persistCache } from 'apollo-cache-persist';
import { SplashScreen } from 'expo';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as React from 'react';
import {
  AsyncStorage,
  Platform,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { enableScreens } from 'react-native-screens';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { ApolloProvider } from '@apollo/client';

import './i18n';
import { graphqlCache, graphqlClient, initFirebase } from './lib';
import useLinking from './navigation/useLinking';

import AuthContext from './components/AuthContext';
import { Colors, Layout } from './constants';
import RootStack from './navigation/RootStack';

const NavigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.white,
  },
};

enableScreens();

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);

  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
            orgId: action.orgId,
            spaceId: action.spaceId,
          };
        case 'SET_SPACE':
          return {
            ...prevState,
            spaceId: action.spaceId,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            orgId: null,
            spaceId: null,
          };
        case 'UNSET_SPACE':
          return {
            ...prevState,
            spaceId: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      orgId: null,
      spaceId: null,
    }
  );

  const authContext = React.useMemo(
    () => ({
      setSpace: async ({ spaceId }) => {
        await AsyncStorage.setItem('spaceId', spaceId);
        dispatch({ type: 'SET_SPACE', spaceId: spaceId });
      },
      signIn: async ({ idToken }) => {
        await AsyncStorage.setItem('idToken', idToken);
        dispatch({ type: 'SIGN_IN', token: idToken });
      },
      signOut: async () => {
        // Reset graphql cache before anything else to avoid some API calls that
        // fail and block sign out.
        await firebase.auth().signOut();
        await AsyncStorage.removeItem('idToken');
        await AsyncStorage.removeItem('orgId');
        await AsyncStorage.removeItem('spaceId');
        // Do this async to speed up logout
        graphqlClient.resetStore();
        dispatch({ type: 'SIGN_OUT' });
      },
      unsetSpace: async () => {
        await AsyncStorage.removeItem('spaceId');
        dispatch({ type: 'UNSET_SPACE' });
      },
    }),
    []
  );

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();

        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Initialize firebase SDK and auth state monitoring
        initFirebase();

        // Load graphql cache
        await persistCache({
          cache: graphqlCache,
          storage: AsyncStorage,
        });
      } catch (e) {
        // We might want to provide this error information to an error
        // reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  React.useEffect(() => {
    // Fetch the token and auth state from storage then navigate to our
    // appropriate place
    const bootstrapAsync = async () => {
      let idToken;
      let orgId;
      let spaceId;

      try {
        idToken = await AsyncStorage.getItem('idToken');
        orgId = await AsyncStorage.getItem('orgId');
        spaceId = await AsyncStorage.getItem('spaceId');
      } catch (e) {
        // Restoring token and auth state failed
        console.warn(e);
      }

      // This will switch to the App screen or Auth screen and this loading
      // screen will be unmounted and thrown away.
      dispatch({
        type: 'RESTORE_TOKEN',
        token: idToken,
        orgId: orgId,
        spaceId: spaceId,
      });
    };

    bootstrapAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <ApolloProvider client={graphqlClient}>
          <AuthContext.Provider value={authContext}>
            <PaperProvider>
              <View style={styles.container}>
                {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
                <NavigationContainer
                  ref={containerRef}
                  initialState={initialNavigationState}
                  theme={NavigationTheme}
                >
                  <RootStack state={state} />
                </NavigationContainer>
              </View>
            </PaperProvider>
          </AuthContext.Provider>
        </ApolloProvider>
      </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
