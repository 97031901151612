import { useFormik } from 'formik';
import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { FOLDER } from './FolderScreen';
import { IconButton, TextInput, TextInputGroup } from '../components/base';
import { Layout, Sizes } from '../constants';
import ResponsiveModal from '../navigation/ResponsiveModal';

const CREATE_FOLDER = gql`
  mutation CreateFolder($input: FolderInput!) {
    createFolder(input: $input) {
      _id
      name
      path
      permissions {
        canDelete
      }
      size
      treeId
      type
    }
  }
`;

export default function CreateFolder(props) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { path, treeId } = props.route.params;

  const [createFolder, { loading, error }] = useMutation(CREATE_FOLDER, {
    update: (cache, { data: { createFolder } }) => {
      const { folder } = cache.readQuery({
        query: FOLDER,
        variables: { path, treeId },
      });
      cache.writeQuery({
        query: FOLDER,
        variables: { path, treeId },
        data: {
          folder: {
            ...folder,
            files: folder.files.concat([
              Object.assign(createFolder, { isUploading: false }),
            ]),
          },
        },
      });
    },
    onCompleted: () => navigation.goBack(),
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: { name: '' },
    onSubmit: values =>
      createFolder({
        variables: {
          input: {
            path: path.concat(path === '/' ? '' : '/', values.name),
            treeId,
          },
        },
      }),
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required()
        .max(100),
    }),
  });

  const headerRight = () => (
    <IconButton
      disabled={!isValid || loading}
      icon="plus"
      onPress={handleSubmit}
      style={styles.headerIcon}
    />
  );

  React.useEffect(() => {
    props.navigation.setOptions({ headerRight: headerRight });
  }, [isValid, loading]);

  return (
    <ResponsiveModal title={t('files.createFolder')} headerRight={headerRight}>
      <TextInputGroup style={styles.group}>
        <TextInput
          placeholder={t('files.inputs.nameLabel')}
          onBlur={handleBlur('name')}
          onChangeText={handleChange('name')}
          value={values.name}
        />
      </TextInputGroup>
    </ResponsiveModal>
  );
}

const styles = StyleSheet.create({
  group: {
    marginTop: Layout.isSmallDevice ? Sizes.content : 0,
  },
  headerIcon: {
    marginRight: Sizes.edge,
  },
});
