import gql from 'graphql-tag';

const POST = gql`
  query Post($postId: ID!) {
    post(postId: $postId) {
      _id
      channel {
        _id
        name
        color
      }
      comments {
        comments {
          _id
          attachments {
            _id
            name
            path
            treeId
          }
          body
          createdAt
          isArchived
          permissions {
            canArchive
            canDelete
            canUpdateBody
          }
          user {
            _id
            fullName
            pictureUrl
          }
        }
        nextPage
      }
      createdAt
      commentCount
      lastCommentedAt
      isSticky
      permissions {
        canAdjustNotifications
        canCreateComment
        canDelete
        canToggleSticky
      }
      room {
        _id
        isSubscriber
        isUser
      }
      title
      toRead
      user {
        _id
        fullName
        pictureUrl
      }
    }
  }
`;

export default POST;
