import React from 'react';
import { FlatList, View } from 'react-native';

import { ActivityIndicator, Divider, ListItem } from './base';
import ErrorNotice from './errors/ErrorNotice';
import EmptyState from './errors/EmptyState';
import ListLoader from './loaders/ListLoader';

export default function OrganizationList({
  data,
  error,
  networkStatus,
  onFetchMore,
  onItemPress,
  refetch,
}) {
  const fetchMore = () => {
    if (data.orgs?.nextPage) {
      onFetchMore(data.orgs.nextPage);
    }
  };

  const ListEmptyComponent = () => <EmptyState onUpdate={() => refetch()} />;
  const ListFooterComponent = () =>
    networkStatus === 3 ? <ActivityIndicator /> : null;

  const renderItem = ({ item }) => (
    <ListItem
      id={item._id}
      title={item.name}
      onPress={() => onItemPress(item)}
    />
  );

  if (networkStatus === 1) {
    return (
      <View style={ListItem.containerStyle}>
        <ListLoader />
      </View>
    );
  }

  if (error) {
    return (
      <View style={ListItem.containerStyle}>
        <ErrorNotice onRetry={() => refetch()} />
      </View>
    );
  }

  return (
    <FlatList
      contentContainerStyle={ListItem.containerStyle}
      data={data?.orgs?.orgs}
      ItemSeparatorComponent={Divider}
      keyExtractor={item => item._id}
      ListEmptyComponent={ListEmptyComponent}
      ListFooterComponent={ListFooterComponent}
      ListFooterComponentStyle={ListItem.containerStyle}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.5}
      onRefresh={() => refetch()}
      refreshing={networkStatus === 4}
      renderItem={renderItem}
    />
  );
}
