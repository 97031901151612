import colorlib from 'color';
import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';
import {
  Button as RNButton,
  Platform,
  StyleSheet,
  Text,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
} from 'react-native';
import { Feather } from '@expo/vector-icons';

import ActivityIndicator from './ActivityIndicator';
import Colors from '../../constants/Colors';
import Sizes from '../../constants/Sizes';

export default class BigButton extends RNButton {
  render() {
    const {
      accessibilityLabel,
      color,
      hasTVPreferredFocus,
      icon,
      onPress,
      loading,
      nextFocusDown,
      nextFocusForward,
      nextFocusLeft,
      nextFocusRight,
      nextFocusUp,
      disabled,
      style,
      title,
      testID,
      touchSoundDisabled,
    } = this.props;
    const colors = {
      background: Colors.primary,
      backgroundDisabled: Colors.disabled,
      text: Colors.white,
      textDisabled: Colors.disabledContrast,
    };
    const buttonStyles = [
      styles.button,
      { backgroundColor: colors.background },
      style,
    ];
    const textStyles = [styles.text, { color: colors.text }];
    if (color) {
      buttonStyles.push({ backgroundColor: color });
    }
    const accessibilityState = {};
    if (disabled || loading) {
      textStyles.push(styles.textDisabled, { color: colors.textDisabled });
      accessibilityState.disabled = true;
    }
    const background =
      disabled || loading
        ? colors.backgroundDisabled
        : color || colors.background;
    const Touchable =
      Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
    return (
      <Touchable
        accessibilityLabel={accessibilityLabel}
        accessibilityRole="button"
        accessibilityState={accessibilityState}
        hasTVPreferredFocus={hasTVPreferredFocus}
        nextFocusDown={nextFocusDown}
        nextFocusForward={nextFocusForward}
        nextFocusLeft={nextFocusLeft}
        nextFocusRight={nextFocusRight}
        nextFocusUp={nextFocusUp}
        testID={testID}
        disabled={disabled || loading}
        onPress={onPress}
        touchSoundDisabled={touchSoundDisabled}
      >
        <View style={[buttonStyles, { backgroundColor: background }]}>
          {loading ? (
            <ActivityIndicator color="white" />
          ) : (
            <React.Fragment>
              {icon && (
                <Feather
                  name={icon}
                  size={Sizes.unit * 4}
                  color={'white'}
                  style={styles.icon}
                />
              )}
              {title && (
                <Text style={textStyles} disabled={disabled}>
                  {title}
                </Text>
              )}
            </React.Fragment>
          )}
        </View>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: Sizes.radius,
    ...Platform.select({
      ios: {},
      android: {
        elevation: 4,
      },
    }),
    flexDirection: 'row',
    padding: Sizes.unit * 3,
    justifyContent: 'center',
  },
  icon: {
    marginRight: Sizes.unit * 3,
  },
  text: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: Sizes.font,
  },
  buttonDisabled: {
    ...Platform.select({
      android: {
        elevation: 0,
      },
    }),
  },
  textDisabled: {},
});
