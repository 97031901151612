import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Link, Text } from '../base';
import { Sizes } from '../../constants';

const ErrorState = function (props) {
  const { t } = useTranslation();

  return (
    <View style={styles.view}>
      <Text style={styles.emoji}>👽</Text>
      <Text style={styles.description}>
        {props.description || t('app.error')}
      </Text>
      {props.onRetry && <Link onPress={props.onRetry}>{t('app.retry')}</Link>}
    </View>
  );
};

const styles = StyleSheet.create({
  description: {
    fontWeight: 'bold',
    marginBottom: Sizes.unit * 2,
  },
  emoji: {
    fontSize: Sizes.unit * 12,
    marginBottom: Sizes.unit * 2,
  },
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ErrorState;
