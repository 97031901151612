import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import { Colors } from '../../constants';

export default function CreatePostFAB({ channel }) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <FAB
      icon="pencil"
      onPress={() =>
        navigation.navigate('CreatePost', {
          channelId: channel._id,
        })
      }
      style={styles.fab}
    />
  );
}

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.primary,
  },
});
