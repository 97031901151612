import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList, StyleSheet, View } from 'react-native';

import ResponsiveModal from '../navigation/ResponsiveModal';
import { Divider, Icon, ListItem, Text } from '../components/base';
import AuthContext from '../components/AuthContext';
import { Layout, Sizes } from '../constants';

function Item({ icon, onPress, title }) {
  return (
    <ListItem
      title={title}
      left={() => (
        <Icon name={icon} size={Sizes.font * 1.25} style={styles.icon} />
      )}
      onPress={() => onPress()}
    />
  );
}

export default function MoreScreen(props) {
  const { signOut, unsetSpace } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const spaceId = props.route.params?.spaceId;

  const MORE_LINKS = [
    {
      title: t('app.thisSpace'),
      data: [
        {
          icon: 'users',
          onPress: () =>
            props.navigation.navigate('SpaceMembers', {
              spaceId,
            }),
          title: t('app.allMembers'),
        },
      ],
    },
    {
      title: t('app.session'),
      data: [
        {
          icon: 'exchange-alt',
          onPress: () => unsetSpace(),
          title: t('app.changeSpace'),
        },
        {
          icon: 'sign-out-alt',
          onPress: () => signOut(),
          title: t('app.logout'),
        },
      ],
    },
  ];

  return (
    <ResponsiveModal title={t('app.moreScreen')}>
      <SectionList
        sections={MORE_LINKS}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => <Item {...item} />}
        renderSectionHeader={({ section: { title } }) => (
          <Text style={styles.header}>{title}</Text>
        )}
      />
    </ResponsiveModal>
  );
}

const styles = StyleSheet.create({
  header: {
    fontWeight: 'bold',
    marginTop: Sizes.unit * 2,
    paddingHorizontal: Sizes.content,
    paddingVertical: Sizes.unit * 2,
  },
  icon: {
    marginRight: Sizes.unit * 4,
  },
  itemSeparator: {
    marginLeft: Sizes.unit * 13,
  },
});
