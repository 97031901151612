import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useQuery } from '@apollo/client';

import { Colors, Sizes } from '../constants';
import { Title } from '../components/base';
import OrgList from '../components/OrgList';
import ResponsiveModal from '../navigation/ResponsiveModal';

export default function SelectOrganizationScreen(props) {
  const { t } = useTranslation();

  const ORGS = gql`
    query Orgs($query: String, $limit: Int, $page: Int) {
      orgs(query: $query, limit: $limit, page: $page) {
        nextPage
        orgs {
          _id
          name
        }
      }
    }
  `;
  const { fetchMore, ...result } = useQuery(ORGS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const onFetchMore = nextPage =>
    fetchMore({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousOrgs = previousResult.orgs;
        const orgs = fetchMoreResult.orgs;
        return {
          orgs: {
            nextPage: orgs.nextPage,
            orgs: [...previousOrgs.orgs, ...orgs.orgs],
            __typename: previousOrgs.__typename,
          },
        };
      },
    });

  return (
    <ResponsiveModal
      title={t('auth.selectOrg')}
      onClose={() => props.navigation.navigate('Home')}
    >
      <OrgList
        {...result}
        onFetchMore={onFetchMore}
        onItemPress={({ _id }) =>
          props.navigation.navigate('Home', { orgId: _id })
        }
      />
    </ResponsiveModal>
  );
}
