import gql from 'graphql-tag';

const CHANNEL_WITH_POSTS = gql`
  query ChannelWithPosts($channelId: ID!, $postLimit: Int) {
    channel(channelId: $channelId) {
      _id
      color
      name
      permissions {
        canAdjustNotifications
        canCreateFile
        canCreatePost
        canDelete
        canMove
        canUpdateName
      }
      posts(limit: $postLimit) {
        posts {
          _id
          last {
            _id
            body
            user {
              _id
              fullName
              pictureUrl
            }
          }
          lastCommentedAt
          isSticky
          title
          toRead
          user {
            _id
            fullName
            pictureUrl
          }
        }
        nextPage
      }
      room {
        _id
        isSubscriber
        isUser
      }
      type
    }
  }
`;

export default CHANNEL_WITH_POSTS;
