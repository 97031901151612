import axios from 'axios';
import * as DocumentPicker from 'expo-document-picker';
import gql from 'graphql-tag';
import { Platform } from 'react-native';

import { graphqlClient as client } from '../../lib';
import { uploads } from '../../lib/graphqlCache';
import FOLDER from './FOLDER';

const UPLOAD_START = gql`
  mutation UploadStart($path: String!, $treeId: ID!) {
    uploadStart(path: $path, treeId: $treeId)
  }
`;

const UPLOAD_FINISH = gql`
  mutation UploadFinish($input: FileInput!) {
    uploadFinish(input: $input) {
      _id
      name
      path
      permissions {
        canDelete
      }
      size
      treeId
      type
    }
  }
`;

const cleanUp = async (file, { path, treeId }) => {
  /*
  const { folder } = client.readQuery({
    query: FOLDER,
    variables: { path, treeId },
  });
  client.writeQuery({
    query: FOLDER,
    variables: { path, treeId },
    data: {
      folder: {
        ...folder,
        files: folder.files.filter((f) => f._id !== filePath(path, file.name)),
      },
    },
  });
  */

  const currentUploads = uploads();
  uploads(
    currentUploads.filter(
      (upload) => upload._id !== treeId + filePath(path, file.name)
    )
  );
};

const filePath = (path, name) => path.concat(path === '/' ? '' : '/', name);

const finishUpload = async (file, { path, treeId }) => {
  const { data } = await client.mutate({
    mutation: UPLOAD_FINISH,
    variables: { input: { path: filePath(path, file.name), treeId } },
  });

  if (!data || !data.uploadFinish) {
    throw new Error('Failed to finish upload.');
  }

  const { folder } = client.readQuery({
    query: FOLDER,
    variables: { path, treeId },
  });
  client.writeQuery({
    query: FOLDER,
    variables: { path, treeId },
    data: {
      folder: {
        ...folder,
        files: folder.files
          .filter((f) => f._id !== filePath(path, file.name))
          .concat([Object.assign(data.uploadFinish, { isUploading: false })]),
      },
    },
  });

  const currentUploads = uploads();
  uploads(
    currentUploads.filter(
      (upload) => upload._id !== treeId + filePath(path, file.name)
    )
  );
};

const handleError = async (error, file, { path, treeId }) => {
  cleanUp(file, { path, treeId });
  console.log(error);
};

const performUpload = (file, postData) => {
  const formData = new FormData();
  const formFile =
    Platform.OS === 'web'
      ? file.file
      : {
          name: file.name,
          uri: file.uri,
          // Require here to use the lib only in RN
          type: require('react-native-mime-types').lookup(file.name),
        };

  Object.keys(postData.fields).forEach((key) => {
    formData.append(key, postData.fields[key]);
  });
  formData.append('file', formFile);

  return axios({
    method: postData.method,
    url: postData.url,
    data: formData,
  });
};

const selectDocument = () => {
  return DocumentPicker.getDocumentAsync({
    copyToCacheDirectory: false,
  }).then((result) => {
    if (!result.cancelled && result.type !== 'cancel') {
      return result;
    }
  });
};

const startUpload = async (file, { path, treeId }) => {
  const cacheFile = {
    __typename: 'File',
    _id: treeId + filePath(path, file.name),
    isUploading: true,
    name: file.name,
    path: filePath(path, file.name),
    permissions: {
      __typename: 'FilePermissions',
      canDelete: false,
    },
    progress: 0,
    size: file.size,
    treeId: treeId,
    type: file.type,
  };
  /*
  const { folder } = client.readQuery({
    query: FOLDER,
    variables: { path, treeId },
  });

  client.writeQuery({
    query: FOLDER,
    variables: { path, treeId },
    data: {
      folder: {
        ...folder,
        files: folder.files.concat([cacheFile]),
      },
    },
  });
  */

  const currentUploads = uploads();
  uploads([...currentUploads, cacheFile]);

  const { data } = await client.mutate({
    mutation: UPLOAD_START,
    variables: { path: filePath(path, file.name), treeId },
  });

  if (!data || !data.uploadStart) {
    throw new Error('Failed to start upload.');
  }

  return JSON.parse(data.uploadStart);
};

const uploadFile = async ({ path, treeId }) => {
  try {
    const file = await selectDocument();
    if (file) {
      return startUpload(file, { path, treeId })
        .then((postData) => performUpload(file, postData))
        .then(() => finishUpload(file, { path, treeId }))
        .catch((error) => handleError(error, file, { path, treeId }));
    }
  } catch (error) {
    console.error(error);
  }
};

export default uploadFile;
