import color from 'color';
import React, { useRef, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Platform, SectionList, StyleSheet, View } from 'react-native';
import { Button, IconButton, List, Surface } from 'react-native-paper';
import { useMutation } from '@apollo/client';

import {
  ActivityIndicator,
  Divider,
  Icon,
  ListItem,
  Text,
} from '../../components/base';
import { Colors, Sizes } from '../../constants';
import { fileUtils } from '../../lib';

const FileItem = ({ file, onDownload, onSelectItem }) => {
  return (
    <ListItem
      id={file._id}
      left={(props) => <Icon {...props} name="file" style={styles.icon} />}
      onPress={() => (file.selectable ? onSelectItem(file) : onDownload(file))}
      title={file.name}
      right={(props) =>
        file.progress >= 0 ? (
          <ActivityIndicator {...props} size="small" style={styles.activity} />
        ) : null
      }
      style={[file.isSelected && styles.selected]}
    />
  );
};

const FolderItem = ({ folder, navigation, onSelectItem, pick }) => (
  <ListItem
    id={folder._id}
    left={(props) => <Icon {...props} name="folder" style={styles.icon} />}
    onPress={() =>
      folder.selectable && !pick
        ? onSelectItem(folder)
        : setTimeout(
            () =>
              navigation.navigate('Folder', {
                path: folder.path,
                treeId: folder.treeId,
              }),
            100
          )
    }
    title={folder.name}
    style={[folder.isSelected && styles.selected]}
  />
);

const Footer = ({
  cursor,
  loading,
  onFetchMore,
  onPressItem,
  selectedFiles,
}) => {
  const { t } = useTranslation();

  return cursor ? (
    <Button
      disabled={loading}
      loading={loading}
      onPress={onFetchMore}
      theme={{ roundness: 0 }}
    >
      {t('app.loadMore')}
    </Button>
  ) : null;
};

export default function FileList({
  files,
  loading,
  navigation,
  onFetchMore,
  onSelectItem,
  pick,
  selectable,
  selectedFiles,
  treeId,
}) {
  const bottomSheet = useRef();
  const { t } = useTranslation();

  const onDownload = (someFile) =>
    fileUtils.download({ path: someFile.path, treeId: treeId });

  // Reverse without mutating the original array
  const mappedFiles = files.files.map((file) => ({
    ...file,
    isSelected: selectedFiles.find((selected) => selected._id === file._id),
    selectable: selectable,
  }));
  const sections = [];

  const uploads = files.uploads;
  const folders = mappedFiles.filter((f) => f.type === 'folder');
  const sectionFiles = mappedFiles.filter(
    (f) => f.type !== 'folder' && !f.isUploading
  );

  if (uploads.length > 0) {
    sections.push({ title: t('files.uploads'), data: uploads });
  }
  if (folders.length > 0) {
    sections.push({ title: t('files.folders'), data: folders });
  }
  if (sectionFiles.length > 0) {
    sections.push({ title: t('files.files'), data: sectionFiles });
  }

  return (
    <SectionList
      sections={sections}
      ItemSeparatorComponent={() => <Divider />}
      keyExtractor={(item) => item._id}
      ListFooterComponent={() => (
        <Footer
          onFetchMore={onFetchMore}
          loading={loading}
          cursor={files.cursor}
        />
      )}
      ListEmptyComponent={() => (
        <ListItem title={t('app.empty')} titleStyle={styles.empty} />
      )}
      renderItem={({ item }) =>
        item.type === 'folder' ? (
          <FolderItem
            folder={item}
            navigation={navigation}
            onSelectItem={onSelectItem}
            pick={pick}
          />
        ) : (
          <FileItem
            file={item}
            onDownload={onDownload}
            onSelectItem={onSelectItem}
          />
        )
      }
      renderSectionHeader={({ section }) => (
        <Text style={styles.sectionHeader}>{section.title}</Text>
      )}
      SectionSeparatorComponent={() => <Divider />}
    />
  );
}

const styles = StyleSheet.create({
  activity: {
    margin: Sizes.unit * 2,
  },
  empty: {
    textAlign: 'center',
  },
  icon: {
    marginRight: Sizes.unit * 3,
  },
  sectionHeader: {
    backgroundColor: Colors.light,
    fontWeight: 'bold',
    paddingHorizontal: Sizes.content,
    paddingVertical: Sizes.unit * 2,
  },
  selected: {
    backgroundColor: color(Colors.secondary).alpha(0.24).rgb().string(),
  },
});
