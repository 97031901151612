import color from 'color';
import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';
import { Image, SectionList, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { withTranslation } from 'react-i18next';
import { DrawerItem } from '@react-navigation/drawer';

import { Icon, IconButton, Text, Title, Touchable } from '../components/base';
import SpaceContext from '../components/SpaceContext';
import { Colors, Sizes } from '../constants';

const backgroundLight = color(Colors.text).alpha(0.08).rgb().string();

class DrawerContent extends React.PureComponent {
  static contextType = SpaceContext;

  _getActiveRoute = (state) => {
    const route = state.routes[state.index];

    if (route?.state) {
      // Dive into nested navigators
      return this._getActiveRoute(route.state);
    }

    return route;
  };

  _keyExtractor = (item, index) => item._id;

  _renderHeader = () => {
    const { navigation, t } = this.props;
    const { space } = this.context;

    return (
      <View style={styles.header}>
        <View>
          <Title style={styles.spaceName}>
            {space ? space.name : t('app.loading')}
          </Title>
        </View>
        {space && (
          <IconButton
            icon="ellipsis-h"
            onPress={() => navigation.navigate('More', { spaceId: space._id })}
            style={styles.more}
          />
        )}
      </View>
    );
  };

  _renderItem = ({ item }) => {
    const backgroundColor = item.active
      ? item.color
        ? color(item.color).alpha(0.08).rgb().string()
        : backgroundLight
      : 'transparent';
    const iconColor = item.color
      ? color(item.color).alpha(0.58).rgb().string()
      : undefined;
    return (
      <View style={[styles.itemContainer, { backgroundColor }]}>
        <Touchable
          onPress={item.onPress ? () => item.onPress(item) : null}
          pressColor={backgroundLight}
          style={styles.item}
        >
          <React.Fragment>
            <Icon color={iconColor} name={item.icon} size={22} solid />
            <Text style={styles.itemLabel}>{item.name}</Text>
            {item.unread && <Text style={styles.itemUnread}>⬤</Text>}
          </React.Fragment>
        </Touchable>
      </View>
    );
  };

  _renderSectionHeader = ({ section: { title } }) => (
    <View style={styles.sectionHeader}>
      <Text style={styles.sectionSubheader}>{this.props.t(title)}</Text>
      {title !== 'app.navigation' && (
        <IconButton
          icon="plus-square"
          size={20}
          onPress={() =>
            this.props.navigation.navigate('SelectChannel', {
              spaceId: this.context.space._id,
              isPlan: title === 'app.studyPlan',
            })
          }
          style={styles.add}
        />
      )}
    </View>
  );

  render() {
    const { navigation, state, t } = this.props;
    const { space } = this.context;
    const activeRoute = this._getActiveRoute(state);

    const navigateToPostFeed = () => navigation.navigate('PostFeed');
    const navigateToFolder = () =>
      navigation.navigate('Folder', {
        path: '/',
        treeId: space._id,
      });
    const navigateToChannel = (channel) => {
      navigation.navigate('Channel', {
        screen: 'Channel',
        params: {
          channelId: channel._id,
          spaceId: space._id,
        },
      });
    };
    const navigateToCourse = (channel) => {
      navigation.navigate('Course', {
        channelId: channel._id,
        defaultTitle: channel.name,
      });
    };

    const navigationData = [
      {
        _id: 'postFeed',
        active: activeRoute?.name === 'PostFeed',
        name: t('app.postFeed'),
        icon: 'inbox',
        onPress: navigateToPostFeed,
      },
    ];

    if (space?.permissions?.canReadFile) {
      navigationData.push({
        _id: 'files',
        name: t('app.files'),
        icon: 'folder',
        onPress: navigateToFolder,
      });
    }

    if (space?.permissions?.canReadInvite) {
      navigationData.push({
        _id: 'invite',
        name: t('app.invitePeople'),
        icon: 'user-plus',
        onPress: () =>
          navigation.navigate('Invite', {
            spaceId: space._id,
          }),
      });
    }

    const sections = [];

    if (space) {
      sections.push({
        title: 'app.navigation',
        data: navigationData,
      });
    }

    if (space?.enablePlan) {
      const courseParams = activeRoute?.params;
      const planElements = space.planElements.channels
        .filter((c) => c.room.isUser)
        .map((c) => ({
          ...c,
          active:
            activeRoute?.name === 'Course' &&
            courseParams &&
            courseParams.channelId === c._id,
          icon: 'bookmark',
          onPress: navigateToCourse,
          unread: c.unreadCount > 0,
        }));
      sections.push({
        title: 'app.studyPlan',
        data:
          planElements.length > 0
            ? planElements
            : [
                {
                  _id: 'noElements',
                  name: t('channels.findCourses'),
                  icon: 'plus',
                  onPress: () =>
                    navigation.navigate('SelectChannel', {
                      spaceId: space._id,
                      isPlan: true,
                    }),
                },
              ],
      });
    }

    if (space) {
      const channelParams = activeRoute?.params;
      const channels = space.channels.channels
        .filter((c) => c.room.isUser)
        .map((c) => ({
          ...c,
          active:
            activeRoute?.name === 'Channel' &&
            channelParams &&
            channelParams.channelId === c._id,
          icon: 'comment-alt',
          onPress: navigateToChannel,
          unread: c.unreadCount > 0,
        }));

      sections.push({
        title: 'app.channels',
        data:
          channels.length > 0
            ? channels
            : [
                {
                  _id: 'noChannels',
                  name: t('channels.findChannels'),
                  icon: 'plus',
                  onPress: () =>
                    navigation.navigate('SelectChannel', {
                      spaceId: space._id,
                      isPlan: false,
                    }),
                },
              ],
      });
    }

    return (
      <SafeAreaView style={styles.area}>
        <SectionList
          sections={sections}
          keyExtractor={this._keyExtractor}
          ListHeaderComponent={this._renderHeader}
          renderItem={this._renderItem}
          renderSectionHeader={this._renderSectionHeader}
        />
      </SafeAreaView>
    );
  }
}

export default withTranslation()(DrawerContent);

const styles = StyleSheet.create({
  add: {
    backgroundColor: 'transparent',
  },
  area: {
    flex: 1,
  },
  empty: {
    color: color('white').alpha(0.56).rgb().string(),
    marginVertical: Sizes.unit,
    textAlign: 'center',
  },
  header: {
    alignItems: 'center',
    backgroundColor: color('white').alpha(0.1).rgb().string(),
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Sizes.unit * 2,
    padding: Sizes.edge,
  },
  item: {
    alignItems: 'center',
    padding: Sizes.unit * 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemContainer: {
    borderRadius: Sizes.radius,
    marginHorizontal: Sizes.edge,
    marginVertical: Sizes.unit,
    overflow: 'hidden',
  },
  itemLabel: {
    flexGrow: 1,
    fontSize: Sizes.font,
    marginLeft: Sizes.unit * 4,
  },
  itemUnread: {
    fontSize: Sizes.font,
  },
  more: {
    backgroundColor: 'transparent',
  },
  sectionHeader: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Sizes.unit,
    paddingHorizontal: Sizes.edge,
  },
  sectionSubheader: {
    fontSize: Sizes.font * 0.95,
    fontWeight: 'bold',
  },
  spaceName: {
    marginBottom: 0,
  },
});
