import { Formik, useFormik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  ScrollView,
  StyleSheet,
  View,
  KeyboardAvoidingView,
} from 'react-native';
import * as yup from 'yup';

import { CHANNEL_WITH_POSTS } from './ChannelScreen';
import {
  Divider,
  IconButton,
  TextInput,
  TextInputGroup,
} from '../components/base';
import { Layout, Sizes } from '../constants';
import ResponsiveModal from '../navigation/ResponsiveModal';

const CREATE_POST = gql`
  mutation CreatePost($input: PostInput!) {
    createPost(input: $input) {
      _id
      last {
        _id
        body
        user {
          _id
          fullName
          pictureUrl
        }
      }
      lastCommentedAt
      isSticky
      title
      toRead
      user {
        _id
        fullName
        pictureUrl
      }
    }
  }
`;

const CreatePostScreen = (props) => {
  const { t } = useTranslation();
  const channelId = props.route?.params?.channelId;
  const defaultValues = { channelId };

  const [createPost, { loading, error }] = useMutation(CREATE_POST, {
    update: (cache, { data: { createPost } }) => {
      const { channel } = cache.readQuery({
        query: CHANNEL_WITH_POSTS,
        variables: { channelId },
      });
      cache.writeQuery({
        query: CHANNEL_WITH_POSTS,
        variables: { channelId },
        data: {
          channel: {
            ...channel,
            posts: {
              ...channel.posts,
              posts: channel.posts.posts.concat([createPost]),
            },
          },
        },
      });
    },
    onCompleted: ({ createPost }) =>
      props.navigation.replace('Post', { postId: createPost._id }),
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    validateForm,
    values,
  } = useFormik({
    initialValues: { title: '' },
    onSubmit: (values) =>
      createPost({ variables: { input: { ...defaultValues, ...values } } }),
    validationSchema: yup.object().shape({
      title: yup.string().required().max(150),
    }),
  });

  const headerRight = () => (
    <IconButton
      icon="paper-plane"
      style={styles.send}
      onPress={handleSubmit}
      disabled={!isValid || loading}
    />
  );

  React.useEffect(() => {
    props.navigation.setOptions({ headerRight: headerRight });
    // Validate form when rendered to ensure that the send button is disabled
    validateForm();
  }, []);

  if (error) alert(t('app.error'));

  return (
    <ResponsiveModal title={t('posts.create')} headerRight={headerRight}>
      <View style={styles.screen}>
        <TextInputGroup>
          <TextInput
            mode="outlined"
            onBlur={handleBlur('title')}
            onChangeText={handleChange('title')}
            placeholder={t('posts.inputs.title')}
            value={values.title}
          />
        </TextInputGroup>
      </View>
    </ResponsiveModal>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    paddingTop: Layout.isSmallDevice ? Sizes.content : 0,
  },
  send: {
    marginRight: Sizes.edge,
  },
});

export default CreatePostScreen;
