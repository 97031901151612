import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Link, Text } from '../base';
import { Sizes } from '../../constants';

const EmptyState = function ({ description, onUpdate }) {
  const { t } = useTranslation();

  return (
    <View style={styles.view}>
      <Text style={styles.emoji}>🌌</Text>
      <Text style={styles.description}>{description || t('app.empty')}</Text>
      {onUpdate && <Link onPress={onUpdate}>{t('app.update')}</Link>}
    </View>
  );
};

const styles = StyleSheet.create({
  description: {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingHorizontal: Sizes.content,
  },
  emoji: {
    fontSize: 46,
  },
  view: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Sizes.unit * 8,
  },
});

export default EmptyState;
