import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Placeholder, PlaceholderLine, Shine } from 'rn-placeholder';

import styles from './loaderStyles';
import { Sizes } from '../../constants';

const ListLoader = () => (
  <View style={styles.container}>
    <Placeholder
      Animation={props => <Shine {...props} style={styles.animation} />}
    >
      <PlaceholderLine
        width={60}
        style={[styles.element, otherStyles.element]}
      />
      <PlaceholderLine
        width={80}
        style={[styles.element, otherStyles.element]}
      />
      <PlaceholderLine
        width={40}
        style={[styles.element, otherStyles.element]}
      />
    </Placeholder>
  </View>
);

const otherStyles = StyleSheet.create({
  element: { marginVertical: Sizes.unit * 4 },
});

export default ListLoader;
