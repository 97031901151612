import gql from 'graphql-tag';

const SPACE = gql`
  query Space($spaceId: ID!) {
    space(spaceId: $spaceId) {
      _id
      channels(mine: true, limit: 50) {
        channels {
          _id
          color
          name
          room {
            _id
            isUser
          }
          unreadCount
          type
        }
        nextPage
      }
      enablePlan
      name
      isPublic
      invite
      permissions {
        canAccess
        canAddSelf
        canChangeInvite
        canCreateChannel
        canCreateFile
        canCreatePlanElement
        canCreateQuestion
        canReadChannel
        canReadFile
        canReadInvite
        canReadQuestion
        canReadShelf
      }
      planElements(mine: true, type: ["course"], limit: 25) {
        channels {
          _id
          color
          name
          room {
            _id
            isUser
          }
          type
          unreadCount
        }
        nextPage
      }
    }
  }
`;

export default SPACE;
