import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useMutation } from '@apollo/client';

import { BigButton, Text } from '../../components/base';
import { Layout, Sizes } from '../../constants';

export const ADD_SUBSCRIBER = gql`
  mutation AddSubscriber($documentId: ID!) {
    addSubscriber(documentId: $documentId) {
      _id
      isSubscriber
      isUser
      space {
        _id
        channels(mine: true, limit: 50) {
          channels {
            _id
            color
            name
            room {
              _id
              isUser
            }
            unreadCount
            type
          }
          nextPage
        }
        planElements(mine: true, type: ["course"], limit: 25) {
          channels {
            _id
            color
            name
            room {
              _id
              isUser
            }
            type
            unreadCount
          }
          nextPage
        }
      }
      subscriberCount
      userCount
    }
  }
`;

export default function JoinButton({ channel }) {
  const { t } = useTranslation();

  const [addSubscriber, { loading }] = useMutation(ADD_SUBSCRIBER);

  return (
    <View style={styles.container}>
      <Text style={styles.caption}>{t('channels.joinCaption')}</Text>
      <BigButton
        disabled={loading}
        icon="user-plus"
        title={
          channel.type === 'course'
            ? t('channels.joinCourse')
            : t('channels.join')
        }
        onPress={() =>
          addSubscriber({
            variables: { documentId: channel._id },
          })
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  caption: {
    fontWeight: 'bold',
    marginBottom: Sizes.unit * 3,
    textAlign: 'center',
  },
  container: {
    padding: Sizes.edge,
    ...(Layout.isSmallDevice
      ? {}
      : {
          alignItems: 'center',
          padding: Sizes.content,
        }),
  },
});
