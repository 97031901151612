import React from 'react';
import { View } from 'react-native';
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Shine,
} from 'rn-placeholder';

import styles from './loaderStyles';

const PostLoader = props => (
  <View>
    <Placeholder
      Animation={props => <Shine {...props} style={styles.animation} />}
      Left={props => (
        <PlaceholderMedia
          {...props}
          isRound={true}
          style={[props.style, styles.element]}
        />
      )}
      style={styles.container}
    >
      <PlaceholderLine width={50} style={styles.element} />
      <PlaceholderLine width={20} style={styles.element} />
    </Placeholder>
    <Placeholder
      Animation={props => <Shine {...props} style={styles.animation} />}
      style={styles.container}
    >
      <PlaceholderLine width={80} style={styles.element} />
      <PlaceholderLine width={30} style={styles.element} />
      <PlaceholderLine width={60} style={styles.element} />
    </Placeholder>
  </View>
);

export default PostLoader;
