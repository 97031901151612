import * as React from 'react';
import { ActivityIndicator as RNActivityIndicator } from 'react-native';

import { Colors, Sizes } from '../../constants';

export default function ActivityIndicator(props) {
  return (
    <RNActivityIndicator {...props} color={props.color || Colors.secondary} />
  );
}
