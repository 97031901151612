import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { Link, Text } from '../base';
import { Sizes } from '../../constants';

const ErrorNotice = function(props) {
  const { t } = useTranslation();

  return (
    <View style={styles.view}>
      <Text style={styles.description}>
        {props.description || t('app.error')}
      </Text>
      {props.onRetry && <Link onPress={props.onRetry}>{t('app.retry')}</Link>}
    </View>
  );
};

const styles = StyleSheet.create({
  description: {
    marginBottom: Sizes.unit * 2,
    fontWeight: 'bold',
  },
  view: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Sizes.unit * 8,
  },
});

export default ErrorNotice;
