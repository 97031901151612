import * as React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import { Icon, Text, Touchable } from './base';
import { Colors, Sizes } from '../constants';
import { fileUtils } from '../lib';

const Item = ({ attachment }) => (
  <Touchable onPress={() => fileUtils.download(attachment)}>
    <View
      style={{
        alignItems: 'center',
        borderColor: Colors.border,
        borderRadius: Sizes.radius,
        borderWidth: StyleSheet.hairlineWidth,
        flexDirection: 'row',
        marginRight: Sizes.unit * 2,
        padding: Sizes.unit * 2,
      }}
    >
      <Icon name="paperclip" style={{ marginRight: Sizes.unit * 2 }} />
      <Text>{attachment.name}</Text>
    </View>
  </Touchable>
);

export default function AttachmentList({ attachments, ...props }) {
  const renderItem = ({ item }) => <Item attachment={item} />;

  return (
    <View {...props}>
      {/* The above View avoid that the FlatList grows too much */}
      <FlatList
        data={attachments}
        horizontal={true}
        renderItem={renderItem}
        keyExtractor={(item) => item._id}
      />
    </View>
  );
}
