import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';
import {
  Button as RNButton,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import Icon from './Icon';
import Touchable from './Touchable';
import { Colors, Sizes } from '../../constants';

export default React.forwardRef(function IconButton(props, ref) {
  const color =
    props.color ||
    (Platform.OS === 'android' ? Colors.text : Colors.neutralContrast);

  return (
    <View style={[styles.container, props.style]}>
      <Touchable
        disabled={props.disabled}
        onPress={props.onPress}
        pressColor={props.pressColor}
        style={styles.touchable}
        ref={ref}
      >
        <View style={styles.content}>
          <Icon name={props.icon} size={props.size || 22} color={color} />
        </View>
      </Touchable>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    borderRadius: Sizes.unit * 1000,
    overflow: 'hidden',
    ...Platform.select({
      android: {
        backgroundColor: 'transparent',
      },
      default: {
        backgroundColor: Colors.neutral,
      },
    }),
  },
  content: {
    alignItems: 'center',
    height: Sizes.unit * 10,
    justifyContent: 'center',
    width: Sizes.unit * 10,
  },
});
