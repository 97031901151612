import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Image, Platform, StyleSheet, View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import POST from './POST';
import { CHANNEL_WITH_POSTS } from '../ChannelScreen';
import { SPACE_WITH_POST_FEED } from '../PostFeedScreen';
import { IconButton, Menu } from '../../components/base';
import { Colors, Layout, Sizes } from '../../constants';

export const ADD_SUBSCRIBER = gql`
  mutation AddSubscriber($documentId: ID!) {
    addSubscriber(documentId: $documentId) {
      _id
      isSubscriber
      isUser
      subscriberCount
      userCount
    }
  }
`;

const DELETE_POST = gql`
  mutation DeletePost($postId: ID!) {
    deletePost(postId: $postId) {
      _id
      channel {
        _id
      }
      space {
        _id
      }
    }
  }
`;

export const REMOVE_SUBSCRIBER = gql`
  mutation RemoveSubscriber($documentId: ID!) {
    removeSubscriber(documentId: $documentId) {
      _id
      isSubscriber
      subscriberCount
    }
  }
`;

export default function PostMore({ post }) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const actions = [];

  if (!post) {
    return null;
  }

  const [addSubscriber] = useMutation(ADD_SUBSCRIBER);

  const [deletePost] = useMutation(DELETE_POST, {
    update: (cache, { data: { deletePost } }) => {
      try {
        const { channel } = cache.readQuery({
          query: CHANNEL_WITH_POSTS,
          variables: { channelId: deletePost.channel._id },
        });
        const { space } = cache.readQuery({
          query: SPACE_WITH_POST_FEED,
          variables: { spaceId: deletePost.space._id },
        });

        cache.writeQuery({
          query: CHANNEL_WITH_POSTS,
          variables: { channelId: deletePost.channel._id },
          data: {
            channel: {
              ...channel,
              posts: {
                ...channel.posts,
                posts: channel.posts.posts.filter(
                  (post) => post._id !== deletePost._id
                ),
              },
            },
          },
        });
        cache.writeQuery({
          query: SPACE_WITH_POST_FEED,
          variables: { spaceId: deletePost.space._id },
          data: {
            space: {
              ...space,
              postFeed: {
                ...space.postFeed,
                posts: space.postFeed.posts.filter(
                  (post) => post._id !== deletePost._id
                ),
              },
            },
          },
        });
      } catch (error) {
        // It's OK for this operation to fail sometimes
      }
    },
    onCompleted: () => navigation.goBack(),
  });

  const [removeSubscriber] = useMutation(REMOVE_SUBSCRIBER);

  const doDeletePost = () => {
    if (Platform.OS === 'web') {
      const ok = confirm(t('posts.deleteDialog.title'));
      if (ok) deletePost({ variables: { postId: post._id } });
    } else {
      Alert.alert(
        t('posts.deleteDialog.title'),
        t('posts.deleteDialog.description'),
        [
          {
            text: t('app.cancel'),
            style: 'cancel',
          },
          {
            text: t('app.delete'),
            onPress: () => deletePost({ variables: { postId: post._id } }),
          },
        ]
      );
    }
  };

  if (post.room.isSubscriber) {
    actions.push({
      icon: 'bell-slash',
      label: t('app.notificationsOff'),
      onPress: () =>
        removeSubscriber({
          variables: { documentId: post._id },
        }),
    });
  } else {
    actions.push({
      icon: 'bell',
      label: t('app.notificationsOn'),
      onPress: () =>
        addSubscriber({
          variables: { documentId: post._id },
        }),
    });
  }

  if (post.permissions.canDelete) {
    actions.push({
      icon: 'trash',
      label: t('app.delete'),
      onPress: () => doDeletePost(),
    });
  }

  if (actions.length < 1) {
    return null;
  }

  return (
    <Menu
      actions={actions}
      Anchor={React.forwardRef(({ onPress }, ref) => (
        <IconButton
          icon={Platform.OS === 'android' ? 'ellipsis-v' : 'ellipsis-h'}
          onPress={onPress}
          ref={ref}
        />
      ))}
    />
  );
}
