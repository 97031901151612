import React from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import CourseBottomTab from './CourseBottomTab';
import DrawerContent from './DrawerContent';
import Hamburger from './Hamburger';
import ResponsiveModal from './ResponsiveModal';
import SpaceContext from '../components/SpaceContext';
import { Colors, Layout, Sizes } from '../constants';
import ErrorScreen from '../screens/ErrorScreen';
import LoadingScreen from '../screens/LoadingScreen';
import ChannelScreen from '../screens/ChannelScreen';
import PostFeedScreen from '../screens/PostFeedScreen';

const DrawerNavigator = createDrawerNavigator();
const PostFeedStack = createStackNavigator();
const ChannelStack = createStackNavigator();

const headerLeft = Layout.isSmallDevice
  ? (props) => <Hamburger {...props} />
  : undefined;

export default function SpaceDrawer(props) {
  const { t } = useTranslation();
  const { space, error } = React.useContext(SpaceContext);

  return (
    <DrawerNavigator.Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      drawerType={
        Layout.isSmallDevice
          ? Platform.OS === 'ios'
            ? 'back'
            : 'front'
          : 'permanent'
      }
    >
      {space ? (
        <React.Fragment>
          <DrawerNavigator.Screen name="PostFeed" component={PostFeed} />
          <DrawerNavigator.Screen name="Channel" component={Channel} />
          <DrawerNavigator.Screen name="Course" component={CourseBottomTab} />
        </React.Fragment>
      ) : error ? (
        <DrawerNavigator.Screen
          name="Error"
          component={ErrorScreen}
          options={{ headerShown: false }}
        />
      ) : (
        <DrawerNavigator.Screen
          name="Loading"
          component={LoadingScreen}
          options={{ headerShown: false }}
        />
      )}
    </DrawerNavigator.Navigator>
  );
}

function PostFeed() {
  const { t } = useTranslation();

  return (
    <PostFeedStack.Navigator>
      <PostFeedStack.Screen
        name="PostFeed"
        component={PostFeedScreen}
        options={{
          headerLeft: headerLeft,
          title: t('app.postFeed'),
        }}
      />
    </PostFeedStack.Navigator>
  );
}

function Channel() {
  const { t } = useTranslation();

  return (
    <ChannelStack.Navigator>
      <ChannelStack.Screen
        name="Channel"
        component={ChannelScreen}
        options={{
          headerLeft: headerLeft,
          title: t('app.loading'),
        }}
      />
    </ChannelStack.Navigator>
  );
}
