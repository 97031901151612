import * as React from 'react';
import { Image } from 'react-native';

import { Sizes } from '../../constants';

export default function AvatarImage({ size, source, style }) {
  const imageSize = size || 40;
  return (
    <Image
      style={[
        { borderRadius: imageSize / 2, height: imageSize, width: imageSize },
        style,
      ]}
      source={source}
    />
  );
}
