import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Menu } from 'react-native-paper';

import Icon from '../Icon';
import Text from '../Text';
import Touchable from '../Touchable';
import { Sizes } from '../../../constants';

export default class CSMenu extends React.Component {
  state = {
    visible: false,
  };

  openMenu = () => this.setState({ visible: true });

  closeMenu = () => this.setState({ visible: false });

  dispatch = onPress => {
    return () => {
      onPress();
      this.closeMenu();
    };
  };

  render() {
    const { actions, Anchor } = this.props;
    return (
      <Menu
        anchor={
          <Anchor onPress={this.openMenu} ref={ref => (this.anchorRef = ref)} />
        }
        contentStyle={styles.content}
        onDismiss={this.closeMenu}
        visible={this.state.visible}
      >
        {actions.map((action, index) => (
          <Touchable
            key={index}
            onPress={this.dispatch(action.onPress)}
            style={styles.item}
          >
            <React.Fragment>
              {action.icon && <Icon name={action.icon} style={styles.icon} />}
              <Text>{action.label}</Text>
            </React.Fragment>
          </Touchable>
        ))}
      </Menu>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    borderRadius: Sizes.radius,
    overflow: 'hidden',
    paddingVertical: 0,
  },
  icon: {
    marginRight: Sizes.unit * 4,
  },
  item: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: Sizes.unit * 40,
    padding: Sizes.unit * 4,
  },
});
