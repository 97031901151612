import gql from 'graphql-tag';
import React, { createRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { IconButton } from '../components/base';
import ChannelList from '../components/ChannelList';
import ErrorState from '../components/errors/ErrorState';
import ListLoader from '../components/loaders/ListLoader';
import { Sizes } from '../constants';
import ResponsiveModal from '../navigation/ResponsiveModal';

const CHANNELS = gql`
  query Channels(
    $spaceId: ID
    $isRoot: Boolean
    $mine: Boolean
    $parentId: ID
    $type: [String]
    $limit: Int
    $withChildren: Boolean!
  ) {
    channels(
      spaceId: $spaceId
      isRoot: $isRoot
      mine: $mine
      parentId: $parentId
      type: $type
      limit: $limit
    ) {
      channels {
        _id
        name
        children(limit: 20) @include(if: $withChildren) {
          channels {
            _id
            name
            color
            room {
              _id
              isUser
            }
            type
          }
        }
        color
        room {
          _id
          isUser
        }
        type
      }
      page
      nextPage
    }
  }
`;

const SelectChannelScreen = props => {
  const { t } = useTranslation();
  const spaceId = props.route.params?.spaceId;
  const isPlan = props.route.params?.isPlan;

  React.useEffect(() => {
    if (isPlan) {
      props.navigation.setOptions({ title: t('app.studyPlan') });
    } else {
      props.navigation.setOptions({
        headerRight: () => <HeaderRight isPlan={isPlan} spaceId={spaceId} />,
      });
    }
  }, []);

  const { data, fetchMore, ...result } = useQuery(CHANNELS, {
    variables: {
      isRoot: true,
      spaceId: spaceId,
      type: isPlan ? ['year', 'other'] : ['regular'],
      limit: 20,
      withChildren: !!isPlan,
    },
    fetchPolicy: 'cache-and-network',
  });

  const channels = data && data.channels;
  const onFetchMore = () =>
    fetchMore({
      variables: {
        limit: 20,
        page: data.channels.nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousChannels = previousResult.channels;
        const channels = fetchMoreResult.channels;
        return {
          channels: {
            ...previousChannels,
            nextPage: channels.nextPage,
            channels: [...previousChannels.channels, ...channels.channels],
          },
        };
      },
    });

  return (
    <ResponsiveModal
      title={t('app.channels')}
      headerRight={() => <HeaderRight isPlan={isPlan} spaceId={spaceId} />}
    >
      <View style={styles.screen}>
        <ChannelList {...result} data={data} onFetchMore={onFetchMore} />
      </View>
    </ResponsiveModal>
  );
};

function HeaderRight({ isPlan, spaceId }) {
  const navigation = useNavigation();
  return isPlan ? null : (
    <IconButton
      icon="plus"
      onPress={() => navigation.navigate('CreateChannel', { spaceId })}
      style={styles.headerIcon}
    />
  );
}

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: Sizes.unit * 4,
    right: 0,
    bottom: 0,
  },
  headerIcon: {
    marginRight: Sizes.edge,
  },
  progress: {
    position: 'absolute',
  },
  screen: {
    flex: 1,
  },
});

export default SelectChannelScreen;
