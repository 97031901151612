import * as React from 'react';
import { useNavigation } from '@react-navigation/native';

import { IconButton } from '../components/base';
import { Colors, Sizes } from '../constants';

export default function Hamburger(props) {
  const navigation = useNavigation();
  return (
    <IconButton
      color={Colors.text}
      icon="bars"
      onPress={() => navigation.openDrawer()}
      size={Sizes.unit * 6}
      style={{ backgroundColor: 'transparent', marginLeft: Sizes.edge }}
    />
  );
}
