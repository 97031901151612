import { format, isBefore, subDays, subYears } from "date-fns";
import { it } from "date-fns/locale";

export const formatTiny = d => {
  const date = new Date(d);
  const now = new Date();
  return isBefore(date, subYears(now, 1))
    ? format(date, "MMM yy", { locale: it })
    : isBefore(date, subDays(now, 1))
    ? format(date, "M MMM", { locale: it })
    : format(date, "p", { locale: it });
};
