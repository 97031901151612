import { AsyncStorage } from 'react-native';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import { firebaseConfig } from '../config';

let isObserving = false;

export default function initFirebase() {
  // Avoid to re-create the DEFAULT app
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  // Avoid to create multiple, duplicated, observers
  if (!isObserving) {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const idToken = await user.getIdToken();
        await AsyncStorage.setItem('idToken', idToken);
      } else {
        await AsyncStorage.removeItem('idToken');
      }
    });

    firebase.auth().onIdTokenChanged(async user => {
      if (user) {
        const idToken = await user.getIdToken();
        await AsyncStorage.setItem('idToken', idToken);
      }
    });

    isObserving = true;
  }
}
