import Constants from 'expo-constants';
import { Platform } from 'react-native';

const devConfig = {
  serverUrl: 'http://localhost:5000/papenseapp/europe-west1',
};

const prodConfig = {
  serverUrl: 'https://europe-west1-papenseapp.cloudfunctions.net',
};

const getAppConfig = ({ releaseChannel }) => {
  if (Platform.OS === 'web' && __DEV__) return devConfig;
  if (Platform.OS === 'web') return prodConfig;
  if (!releaseChannel) return devConfig;
  if (releaseChannel === 'default') return prodConfig;
  if (releaseChannel.indexOf('prod') !== -1) return prodConfig;

  return devConfig;
};

export default getAppConfig(Constants.manifest);
