import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import CreateComment from './CreateComment';
import CommentActions from './CommentActions';
import { Colors } from '../../constants';

export default function Toolbar({
  editableComment,
  onCommentEdited,
  post,
  selectable,
  selectedComments,
  resetSelected,
}) {
  return (
    <View style={styles.container}>
      {selectable ? (
        <CommentActions
          post={post}
          selectedComments={selectedComments}
          resetSelected={resetSelected}
        />
      ) : (
        <CreateComment
          editableComment={editableComment}
          onCommentEdited={onCommentEdited}
          post={post}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: Colors.border,
  },
});
