import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { createStackNavigator } from '@react-navigation/stack';

import SPACE from './SPACE';
import SpaceDrawer from './SpaceDrawer';
import SpaceContext from '../components/SpaceContext';
import { Layout } from '../constants';
import CreateChannelScreen from '../screens/CreateChannelScreen';
import CreateFolderScreen from '../screens/CreateFolderScreen';
import CreatePostScreen from '../screens/CreatePostScreen';
import FolderScreen from '../screens/FolderScreen';
import HomeScreen from '../screens/HomeScreen';
import InviteScreen from '../screens/InviteScreen';
import MoreScreen from '../screens/MoreScreen';
import PostScreen from '../screens/PostScreen';
import SelectChannelScreen from '../screens/SelectChannelScreen';
import SelectOrgScreen from '../screens/SelectOrgScreen';
import SelectSpaceScreen from '../screens/SelectSpaceScreen';
import SpaceMembersScreen from '../screens/SpaceMembersScreen';
import UseInviteScreen from '../screens/UseInviteScreen';
import UserProfileScreen from '../screens/UserProfileScreen';

const Stack = createStackNavigator();

export default function RootStack(props) {
  const { state } = props;
  const { t } = useTranslation();

  const [getSpace, { data, error, loading }] = useLazyQuery(SPACE, {
    pollInterval: 60 * 1000,
  });

  React.useEffect(() => {
    if (state.spaceId) {
      getSpace({ variables: { spaceId: state.spaceId } });
    }
  }, [state.spaceId]);

  const modalOptions = Layout.isSmallDevice
    ? {}
    : {
        cardStyle: { backgroundColor: 'rgba(0,0,0,0.15)' },
        cardOverlayEnabled: true,
        headerShown: false,
      };

  return (
    <SpaceContext.Provider
      value={{ error: error, loading: loading, space: data?.space }}
    >
      <Stack.Navigator mode="modal">
        {state.userToken == null ? (
          <React.Fragment>
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="SelectOrg"
              component={SelectOrgScreen}
              options={{
                ...modalOptions,
                title: t('auth.selectOrg'),
              }}
            />
          </React.Fragment>
        ) : state.spaceId ? (
          <React.Fragment>
            <Stack.Screen
              name="Main"
              component={SpaceDrawer}
              initialParams={{ spaceId: state.spaceId }}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="More"
              component={MoreScreen}
              options={{
                ...modalOptions,
                title: t('app.moreScreen'),
              }}
            />
            <Stack.Screen
              name="Post"
              component={PostScreen}
              options={{
                ...modalOptions,
                title: t('app.post'),
              }}
            />
            <Stack.Screen
              name="CreatePost"
              component={CreatePostScreen}
              options={{
                ...modalOptions,
                title: t('posts.create'),
              }}
            />
            <Stack.Screen
              name="SelectChannel"
              component={SelectChannelScreen}
              options={{
                ...modalOptions,
                title: t('app.channels'),
              }}
            />
            <Stack.Screen
              name="CreateChannel"
              component={CreateChannelScreen}
              options={{
                ...modalOptions,
                title: t('channels.create'),
              }}
            />
            <Stack.Screen
              name="Folder"
              component={FolderScreen}
              options={{
                ...modalOptions,
                title: t('app.files'),
              }}
            />
            <Stack.Screen
              name="CreateFolder"
              component={CreateFolderScreen}
              options={{
                ...modalOptions,
                title: t('files.createFolder'),
              }}
            />
            <Stack.Screen
              name="SpaceMembers"
              component={SpaceMembersScreen}
              options={{
                ...modalOptions,
                title: t('app.allMembers'),
              }}
            />
            <Stack.Screen
              name="UserProfile"
              component={UserProfileScreen}
              options={{
                ...modalOptions,
                title: t('users.profile'),
              }}
            />
            <Stack.Screen
              name="Invite"
              component={InviteScreen}
              options={{
                ...modalOptions,
                title: t('app.invitePeople'),
              }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Stack.Screen
              name="SelectSpace"
              component={SelectSpaceScreen}
              initialParams={{ mine: true }}
              options={{ title: t('spaces.mine') }}
            />
            <Stack.Screen
              name="UseInvite"
              component={UseInviteScreen}
              options={{
                ...modalOptions,
                title: t('app.useInvite'),
              }}
            />
          </React.Fragment>
        )}
      </Stack.Navigator>
    </SpaceContext.Provider>
  );
}
