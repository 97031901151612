import gql from 'graphql-tag';
import React, { useState } from 'react';
import {
  Alert,
  FlatList,
  Platform,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ActivityIndicator, Divider, ListItem, Text, Touchable } from './base';
import ErrorNotice from './errors/ErrorNotice';
import EmptyState from './errors/EmptyState';
import ListLoader from './loaders/ListLoader';
import { Colors, Sizes } from '../constants';

const ADD_SELF_TO_SPACE = gql`
  mutation AddSelfToSpace($spaceId: ID!) {
    addSelfToSpace(spaceId: $spaceId) {
      _id
      spaces {
        spaces {
          _id
          name
          isMember
        }
      }
    }
  }
`;

export default function SpaceList({
  data,
  error,
  loading,
  networkStatus,
  onAdd,
  onFetchMore,
  refetch,
}) {
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [adding, setAdding] = useState(null);
  const { t } = useTranslation();

  const [addSelfToSpace] = useMutation(ADD_SELF_TO_SPACE, {
    onCompleted: () => {
      onAdd(adding);
      setAdding(null);
    },
  });

  const fetchMore = () => {
    if (data.spaces?.nextPage) {
      onFetchMore(data.spaces.nextPage);
    }
  };

  const addSelf = (space) => {
    const dispatch = () => {
      setAdding(space);
      addSelfToSpace({
        variables: { spaceId: space._id },
      });
    };

    if (Platform.OS === 'web') {
      const ok = confirm(t('spaces.joinIn', { name: space ? space.name : '' }));
      if (ok) dispatch();
    } else {
      Alert.alert(
        t('spaces.joinIn', { name: space ? space.name : '' }),
        t('spaces.joinInDescription'),
        [
          {
            text: t('app.cancel'),
            style: 'cancel',
          },
          {
            text: t('spaces.join'),
            onPress: () => dispatch(),
          },
        ]
      );
    }
  };

  const renderItem = ({ item }) => (
    <ListItem
      disabled={!item.permissions.canAddSelf && !item.isMember}
      id={item._id}
      title={item.name}
      description={{
        public: t('spaces.public'),
        open: item.permissions.canAddSelf
          ? t('spaces.open')
          : t('spaces.open') + ' — ' + t('spaces.orgMembershipRequired'),
        closed: t('spaces.closed') + ' — ' + t('spaces.manualJoinRequired'),
      }[item.discoverability].concat(
        item.isMember ? ' — ' + t('spaces.isMember') : ''
      )}
      onPress={() => (item.isMember ? onAdd(item) : addSelf(item))}
    />
  );

  const ListEmptyComponent = () => {
    if (networkStatus === 1) {
      return (
        <View style={ListItem.containerStyle}>
          <ListLoader />
        </View>
      );
    } else {
      return <EmptyState onUpdate={() => refetch()} />;
    }
  };
  const ListFooterComponent = () =>
    networkStatus === 3 ? <ActivityIndicator /> : null;

  return (
    <FlatList
      contentContainerStyle={ListItem.containerStyle}
      data={data?.spaces?.spaces}
      ItemSeparatorComponent={Divider}
      keyExtractor={(item) => item._id}
      ListEmptyComponent={ListEmptyComponent}
      ListFooterComponent={ListFooterComponent}
      ListFooterComponentStyle={ListItem.containerStyle}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.5}
      onRefresh={() => refetch()}
      refreshing={networkStatus === 4}
      renderItem={renderItem}
    />
  );
}
