import gql from 'graphql-tag';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, Switch, View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';

import {
  AvatarImage,
  Divider,
  ListItem,
  Text,
  Title,
} from '../components/base';
import ListLoader from '../components/loaders/ListLoader';
import ResponsiveModal from '../navigation/ResponsiveModal';
import { Colors, Sizes } from '../constants';

const TOGGLE_USER_ROLE = gql`
  mutation ToggleUserRole($scopeSetId: ID!, $spaceId: ID!, $userId: ID!) {
    toggleUserRole(
      scopeSetId: $scopeSetId
      spaceId: $spaceId
      userId: $userId
    ) {
      _id
      scopeSets(spaceId: $spaceId) {
        scopeSets {
          _id
          isEnabled
          name
          scopes
        }
        nextPage
      }
    }
  }
`;

const USER = gql`
  query User($spaceId: ID!, $userId: ID!) {
    user(userId: $userId) {
      _id
      fullName
      pictureUrl
      permissions {
        canEditNotifications
        canEditRoles(spaceId: $spaceId)
      }
      posts(spaceId: $spaceId, limit: 1) {
        posts {
          _id
          title
        }
      }
      scopeSets(spaceId: $spaceId) {
        scopeSets {
          _id
          isEnabled
          name
          scopes
        }
        nextPage
      }
    }
  }
`;

const LastPost = ({ post }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Text style={styles.subheader}>{t('posts.last')}</Text>
      <View style={styles.lastPost}>
        <Text>{post.title}</Text>
      </View>
    </React.Fragment>
  );
};

const UserRoles = ({ spaceId, user }) => {
  const [toggleUserRole] = useMutation(TOGGLE_USER_ROLE);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Text style={styles.subheader}>{t('users.roles')}</Text>
      {user.scopeSets.scopeSets.map((scopeSet) => (
        <ListItem
          key={scopeSet._id}
          title={t(`roles.${scopeSet.name}`)}
          right={() => {
            return (
              <Switch
                value={scopeSet.isEnabled}
                onValueChange={() =>
                  toggleUserRole({
                    variables: {
                      scopeSetId: scopeSet._id,
                      spaceId,
                      userId: user._id,
                    },
                    optimisticResponse: {
                      toggleUserRole: {
                        __typename: 'User',
                        _id: user._id,
                        scopeSets: {
                          ...user.scopeSets,
                          scopeSets: user.scopeSets.scopeSets.map((scSet) =>
                            scSet._id === scopeSet._id
                              ? Object.assign(scSet, {
                                  isEnabled: !scopeSet.isEnabled,
                                })
                              : scSet
                          ),
                        },
                      },
                    },
                  })
                }
              />
            );
          }}
        />
      ))}
    </React.Fragment>
  );
};

export default function UserProfileScreen(props) {
  const { t } = useTranslation();
  const userId = props.route.params?.userId;
  const spaceId = props.route.params?.spaceId;

  const { loading, data } = useQuery(USER, {
    variables: { userId, spaceId },
    fetchPolicy: 'cache-and-network',
  });

  const user = data && data.user;

  return (
    <ResponsiveModal title={t('users.profile')}>
      <ScrollView style={styles.screen}>
        {user ? (
          <React.Fragment>
            <View style={styles.profile}>
              <AvatarImage
                size={64}
                source={{ uri: user.pictureUrl }}
                style={styles.avatar}
              />
              <Title>{user.fullName}</Title>
            </View>
            <Divider />
            {user && !user.posts.posts[0] && !user.permissions.canEditRoles && (
              <Text style={styles.empty}>{t('app.empty')}</Text>
            )}
            {user && user.posts.posts[0] && (
              <LastPost post={user.posts.posts[0]} />
            )}
            {user && user.permissions.canEditRoles && (
              <UserRoles user={user} spaceId={spaceId} />
            )}
          </React.Fragment>
        ) : loading ? (
          <ListLoader />
        ) : null}
      </ScrollView>
    </ResponsiveModal>
  );
}

const styles = StyleSheet.create({
  avatar: {
    margin: Sizes.unit * 3,
  },
  empty: {
    color: Colors.muted,
    marginVertical: Sizes.unit * 15,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  lastPost: {
    borderColor: Colors.border,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: Sizes.radius,
    marginHorizontal: Sizes.content,
    padding: Sizes.unit * 3,
  },
  profile: {
    alignItems: 'center',
    paddingVertical: Sizes.unit * 5,
  },
  screen: {
    flex: 1,
  },
  section: {
    paddingHorizontal: Sizes.content,
  },
  subheader: {
    paddingHorizontal: Sizes.content,
    paddingVertical: Sizes.unit * 3,
    fontWeight: 'bold',
  },
});
