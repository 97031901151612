import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import ChannelMore from './ChannelMore';
import { IconButton } from '../../components/base';
import { Colors, Sizes } from '../../constants';

export default function HeaderRight({ channel }) {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <ChannelMore channel={channel} />
      {channel.room.isUser && (
        <IconButton
          color={Colors.white}
          icon="edit"
          onPress={() =>
            navigation.navigate('CreatePost', {
              channelId: channel._id,
            })
          }
          style={styles.iconButton}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  iconButton: {
    backgroundColor: Colors.primary,
    marginRight: Sizes.edge,
  },
});
