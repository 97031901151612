import * as React from 'react';

import Text from './Text';
import { Colors, Sizes } from '../../constants';

export default function Link({ onPress, style, ...props }) {
  return (
    <Text
      {...props}
      onPress={onPress}
      style={[
        {
          color: 'blue',
          textDecorationLine: 'underline',
        },
        style,
      ]}
    />
  );
}
