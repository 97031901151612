import color from 'color';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { Divider, Icon, ListItem, Text } from './base';
import { Colors, Sizes } from '../constants';

const ChannelItem = ({ channel }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  return (
    <ListItem
      id={channel._id}
      title={channel.name}
      description={channel.room?.isUser && t('channels.isUser')}
      left={(props) => (
        <Icon
          {...props}
          color={color(channel.color).alpha(0.58).rgb().string()}
          name={channel.type === 'course' ? 'bookmark' : 'comment-alt'}
          style={styles.icon}
          solid
        />
      )}
      onPress={() => {
        if (channel.type === 'course') {
          navigation.navigate('Course', {
            channelId: channel._id,
            defaultTitle: channel.name,
          });
        } else {
          navigation.navigate('Channel', {
            screen: 'Channel',
            params: {
              channelId: channel._id,
            },
          });
        }
      }}
    />
  );
};

export default function ChannelList({
  data,
  networkStatus,
  onFetchMore,
  refetch,
}) {
  const channels = data?.channels;
  const sections = [];

  const fetchMore = () => {
    if (data.channels.nextPage) {
      onFetchMore();
    }
  };

  if (channels) {
    channels.channels.forEach((channel) => {
      if (channel.children?.channels.length > 0) {
        sections.push({
          title: channel.name,
          data: channel.children.channels,
        });
      } else {
        const firstChar = channel.name.charAt(0).toUpperCase();
        const sectionIndex = sections.findIndex(
          (section) => section.title === firstChar
        );

        if (sectionIndex > -1) {
          sections[sectionIndex].data.push(channel);
        } else {
          sections.push({
            title: firstChar,
            data: [channel],
          });
        }
      }
    });
  }

  return (
    <SectionList
      sections={sections}
      ItemSeparatorComponent={() => <Divider />}
      keyExtractor={(item, index) => item._id}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.5}
      onRefresh={refetch}
      refreshing={networkStatus === 4}
      renderItem={({ item }) => <ChannelItem channel={item} />}
      renderSectionHeader={({ section: { title } }) => (
        <Text style={styles.header}>{title}</Text>
      )}
      SectionSeparatorComponent={() => <Divider />}
    />
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: Colors.light,
    fontWeight: 'bold',
    paddingHorizontal: Sizes.content,
    paddingVertical: Sizes.unit * 2,
  },
  icon: {
    marginRight: Sizes.unit * 2,
    marginTop: Sizes.unit / 2,
  },
});
