import Layout from './Layout';

const UNIT = 4;

const FONT = {
  font: UNIT * 4,
  icon: UNIT * 5,
};

const LAYOUT = {
  maxWidth: UNIT * 240,
};

const RADIUS = {
  radius: UNIT * 2,
};

const SPACING = {
  edge: UNIT * 2,
  content: UNIT * 4,
  contentTop: Layout.isSmallDevice ? null : UNIT * 6,
};

export default {
  ...FONT,
  ...LAYOUT,
  ...SPACING,
  ...RADIUS,
  unit: UNIT,
};
