import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import { useLazyQuery } from '@apollo/client';

import CreateFAB from './CreateFAB';
import FOLDER from './FOLDER';
import HeaderRight from './HeaderRight';
import { Icon, ListItem } from '../../components/base';
import CourseContext from '../../components/CourseContext';
import FileList from '../../components/FileList';
import EmptyState from '../../components/errors/EmptyState';
import ErrorState from '../../components/errors/ErrorState';
import ListLoader from '../../components/loaders/ListLoader';
import { Colors, Sizes } from '../../constants';
import { uploadFile, fileUtils } from '../../lib';
import ResponsiveModal from '../../navigation/ResponsiveModal';

export default function FolderScreen(props) {
  const { t } = useTranslation();
  const courseContext = React.useContext(CourseContext);
  const [treeId, setTreeId] = React.useState(null);
  const [path, setPath] = React.useState('/');
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const {
    callback,
    noModal = false,
    pick = false,
    selectable = false,
    ...params
  } = props.route.params;
  const defaultTitle = courseContext?.defaultTitle;
  const title = selectable ? t('files.select') : t('app.files');

  const setSelectable = (bool) =>
    props.navigation.setParams({ selectable: !!bool });
  const onToggleSelectable = () => {
    setSelectable(!selectable);
    setSelectedFiles([]);
  };

  React.useEffect(() => {
    setTreeId(params.treeId);
    setPath('/');
  }, [params.treeId]);

  React.useEffect(() => {
    if (courseContext?.channelId) {
      setTreeId(courseContext.channelId);
      setPath('/');
    }
  }, [courseContext]);

  React.useEffect(() => {
    setPath(params.path || '/');
  }, [params.path]);

  const [getFolder, { loading, data, refetch, fetchMore }] = useLazyQuery(
    FOLDER,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  React.useEffect(() => {
    props.navigation.setOptions({
      title: defaultTitle || title,
    });
  }, [defaultTitle, selectable]);

  React.useEffect(() => {
    props.navigation.setOptions({
      headerRight: () => (
        <HeaderRight
          onPick={onPick}
          onToggleSelectable={onToggleSelectable}
          path={path}
          pick={pick}
          selectable={selectable}
          selectedFiles={selectedFiles}
          treeId={treeId}
        />
      ),
    });
  }, [path, selectable, selectedFiles, treeId]);

  React.useEffect(() => {
    if (treeId && path) {
      getFolder({ variables: { path: path, treeId: treeId } });
    }
  }, [treeId, path]);

  const folder = data && data.folder;
  const parent = folder && {
    path: fileUtils.parentPath(path),
    treeId: treeId,
    isRoot: path === '/',
  };
  const displayPath =
    path.split('/').length > 2
      ? '.../'.concat(
          path
            .split('/')
            .slice(path.split('/').length - 2)
            .join('/')
        )
      : path;

  const fetchMoreFiles = () => {
    fetchMore({
      query: FOLDER,
      variables: {
        path: path,
        treeId: treeId,
        limit: 20,
        cursor: folder.cursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!previousResult || !fetchMoreResult) return previousResult;

        const previousFolder = previousResult.folder;
        const folder = fetchMoreResult.folder;

        folder.files = folder.files.map((f) =>
          Object.assign(f, { isUploading: false })
        );

        return {
          folder: {
            ...previousFolder,
            cursor: folder.cursor,
            files: [...previousFolder.files, ...folder.files],
          },
        };
      },
    });
  };

  const onPick = () => {
    props.navigation.navigate({
      key: callback,
      params: { picks: selectedFiles },
    });
  };
  const onSelectItem = (file) => {
    if (selectedFiles.find((selected) => selected._id === file._id)) {
      setSelectedFiles(
        selectedFiles.filter((selected) => selected._id !== file._id)
      );
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };
  const resetSelected = () => () => setSelectedFiles([]);

  return (
    <ResponsiveModal
      disabled={noModal}
      title={title}
      headerRight={() => (
        <HeaderRight
          onPick={onPick}
          onToggleSelectable={onToggleSelectable}
          path={path}
          pick={pick}
          selectable={selectable}
          selectedFiles={selectedFiles}
          treeId={treeId}
        />
      )}
    >
      {parent && !parent.isRoot && (
        <ListItem
          left={(props) => (
            <Icon {...props} name="chevron-up" style={styles.icon} />
          )}
          title={displayPath}
          onPress={() =>
            setTimeout(
              () =>
                props.navigation.navigate('Folder', {
                  path: parent.path,
                  treeId: parent.treeId,
                }),
              100
            )
          }
        />
      )}
      {folder ? (
        <FileList
          files={folder}
          loading={loading}
          onFetchMore={fetchMoreFiles}
          onSelectItem={onSelectItem}
          pick={pick}
          selectable={selectable}
          selectedFiles={selectedFiles}
          treeId={treeId}
          {...props}
        />
      ) : loading ? (
        <ListLoader />
      ) : (
        <EmptyState onUpdate={() => refetch()} />
      )}
      {Platform.OS === 'android' && <CreateFAB path={path} treeId={treeId} />}
    </ResponsiveModal>
  );
}

const styles = StyleSheet.create({
  empty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
  },
  header: {
    elevation: 1,
  },
  icon: {
    marginRight: Sizes.unit * 3,
  },
  progress: {
    position: 'absolute',
  },
});
