import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { useQuery } from '@apollo/client';

import PostList from '../components/PostList';
import EmptyState from '../components/errors/EmptyState';
import SpaceContext from '../components/SpaceContext';

export const SPACE_WITH_POST_FEED = gql`
  query SpaceWithPostFeed($postLimit: Int, $postPage: Int, $spaceId: ID!) {
    space(spaceId: $spaceId) {
      _id
      postFeed(limit: $postLimit, page: $postPage) {
        nextPage
        posts {
          _id
          last {
            _id
            body
            user {
              _id
              fullName
              pictureUrl
            }
          }
          lastCommentedAt
          isSticky
          title
          toRead
          user {
            _id
            fullName
            pictureUrl
          }
        }
      }
    }
  }
`;

const Onboarding = ({ navigation, spaceId }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.onboarding}>
      <Text style={styles.emoji}>🚀</Text>
      <Title>{t('onboarding.findGroups')}</Title>
      <Button
        icon="plus-circle"
        mode="outlined"
        onPress={() => navigation.navigate('SelectChannel', { spaceId })}
      >
        {t('onboarding.go')}
      </Button>
    </View>
  );
};

export default function PostFeedScreen(props) {
  const { space } = React.useContext(SpaceContext);
  const { t } = useTranslation();

  const { data, fetchMore, ...result } = useQuery(SPACE_WITH_POST_FEED, {
    notifyOnNetworkStatusChange: true,
    skip: !space || !space.permissions.canReadChannel,
    variables: { spaceId: space._id, limit: 20 },
  });

  const fetchMorePosts = () =>
    fetchMore({
      variables: {
        limit: 20,
        page: data.space.postFeed.nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousSpace = previousResult.space;
        const space = fetchMoreResult.space;
        return {
          space: {
            ...previousSpace,
            postFeed: {
              nextPage: space.postFeed.nextPage,
              posts: [...previousSpace.postFeed.posts, ...space.postFeed.posts],
              __typename: previousSpace.postFeed.__typename,
            },
          },
        };
      },
    });

  return (
    <View style={styles.screen}>
      {space.permissions.canReadChannel ? (
        <PostList
          {...result}
          onFetchMore={fetchMorePosts}
          onItemPress={(item) =>
            props.navigation.navigate('Post', { postId: item._id })
          }
          data={data?.space?.postFeed}
        />
      ) : (
        <EmptyState
          description={t('app.noThreadsPermission')}
          onUpdate={() => result.refetch()}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  emoji: {
    fontSize: 46,
  },
  onboarding: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    position: 'absolute',
  },
  screen: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
