import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import uploadFile from './uploadFile';
import { Colors } from '../../constants';

export default function CreateFAB({ path, treeId }) {
  const [openFAB, setOpenFAB] = React.useState();
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <FAB.Group
      color={Colors.white}
      open={openFAB}
      icon="plus"
      actions={[
        {
          icon: 'folder-plus-outline',
          label: t('files.createFolder'),
          onPress: () => navigation.navigate('CreateFolder', { path, treeId }),
        },
        {
          icon: 'cloud-upload',
          label: t('files.uploadFile'),
          onPress: () => uploadFile({ path, treeId }),
        },
      ]}
      onStateChange={({ open }) => setOpenFAB(open)}
      fabStyle={styles.fab}
    />
  );
}

const styles = StyleSheet.create({
  fab: {
    backgroundColor: Colors.primary,
  },
});
