import gql from 'graphql-tag';

const FOLDER = gql`
  query Folder($path: String!, $treeId: ID!, $cursor: String, $limit: Int) {
    folder(path: $path, treeId: $treeId, cursor: $cursor, limit: $limit)
      @connection(key: "folder", filter: ["path", "treeId"]) {
      files {
        ...FolderFile
      }
      cursor
      uploads @client {
        ...FolderFile
        progress
      }
    }
  }

  fragment FolderFile on File {
    _id
    name
    path
    permissions {
      canDelete
    }
    size
    treeId
    type
  }
`;

export default FOLDER;
