import gql from 'graphql-tag';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';

import {
  IconButton,
  Text,
  TextInput,
  TextInputGroup,
} from '../components/base';
import { Colors, Sizes } from '../constants';
import ResponsiveModal from '../navigation/ResponsiveModal';
import SPACE from '../navigation/SPACE';

const CREATE_CHANNEL = gql`
  mutation CreateChannel($input: ChannelInput!) {
    createChannel(input: $input) {
      _id
      color
      name
      room {
        _id
        isUser
      }
      unreadCount
      type
    }
  }
`;

const CreateChannelScreen = props => {
  const { t } = useTranslation();
  const spaceId = props.route.params?.spaceId;
  const defaultValues = { spaceId };

  const [createChannel, { loading, error }] = useMutation(CREATE_CHANNEL, {
    update: (cache, { data: { createChannel } }) => {
      const { space } = cache.readQuery({
        query: SPACE,
        variables: { spaceId },
      });
      cache.writeQuery({
        query: SPACE,
        variables: { spaceId },
        data: {
          space: {
            ...space,
            channels: {
              ...space.channels,
              channels: space.channels.channels.concat([createChannel]),
            },
          },
        },
      });
    },
    onCompleted: ({ createChannel }) =>
      props.navigation.navigate('Channel', {
        screen: 'Channel',
        params: {
          channelId: createChannel._id,
          spaceId: spaceId,
        },
      }),
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: { name: '' },
    onSubmit: values =>
      createChannel({ variables: { input: { ...defaultValues, ...values } } }),
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required()
        .max(40),
    }),
  });

  React.useEffect(() => {
    props.navigation.setOptions({
      headerRight: () => (
        <HeaderRight
          handleSubmit={handleSubmit}
          isValid={isValid}
          loading={loading}
        />
      ),
    });
  }, []);

  if (error) alert(t('app.error'));

  return (
    <ResponsiveModal
      title={t('channels.create')}
      headerRight={() => (
        <HeaderRight
          handleSubmit={handleSubmit}
          isValid={isValid}
          loading={loading}
        />
      )}
    >
      <View style={styles.screen}>
        <TextInputGroup>
          <TextInput
            placeholder={t('channels.inputs.nameLabel')}
            mode="outlined"
            onBlur={handleBlur('name')}
            onChangeText={handleChange('name')}
            style={styles.input}
            value={values.name}
          />
        </TextInputGroup>
        <Text muted={true} style={styles.hint}>
          {t('channels.inputs.nameHint')}
        </Text>
      </View>
    </ResponsiveModal>
  );
};

function HeaderRight({ handleSubmit, isValid, loading }) {
  return (
    <IconButton
      disabled={!isValid || loading}
      icon="plus"
      onPress={handleSubmit}
      style={styles.headerIcon}
    />
  );
}

const styles = StyleSheet.create({
  headerIcon: {
    marginRight: Sizes.edge,
  },
  hint: {
    fontSize: Sizes.font * 0.85,
    paddingVertical: Sizes.unit * 2,
    paddingHorizontal: Sizes.content,
  },
  input: { paddingHorizontal: Sizes.unit },
  screen: { flex: 1, paddingTop: Sizes.content },
});

export default CreateChannelScreen;
