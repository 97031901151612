import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Image, Platform, StyleSheet, View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { IconButton, Menu } from '../../components/base';
import { Colors, Layout, Sizes } from '../../constants';
import SPACE from '../../navigation/SPACE';

export const DELETE_CHANNEL = gql`
  mutation DeleteChannel($channelId: ID!) {
    deleteChannel(channelId: $channelId) {
      _id
      space {
        _id
      }
    }
  }
`;

const REMOVE_USER = gql`
  mutation RemoveUser($documentId: ID!) {
    removeUser(documentId: $documentId) {
      _id
      isSubscriber
      isUser
      subscriberCount
      userCount
    }
  }
`;

export default function ChannelMore({ channel }) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const actions = [];

  const [deleteChannel, { loading, error }] = useMutation(DELETE_CHANNEL, {
    update: (cache, { data: { deleteChannel } }) => {
      const { space } = cache.readQuery({
        query: SPACE,
        variables: { spaceId: deleteChannel.space._id },
      });
      cache.writeQuery({
        query: SPACE,
        variables: { spaceId: deleteChannel.space._id },
        data: {
          space: {
            ...space,
            channels: {
              ...space.channels,
              channels: space.channels.channels.filter(
                (c) => c._id !== deleteChannel._id
              ),
            },
          },
        },
      });
    },
    onCompleted: () => navigation.navigate('PostFeed'),
  });

  const [removeUser] = useMutation(REMOVE_USER);

  const doDeleteChannel = () => {
    if (Platform.OS === 'web') {
      const ok = confirm(t('channels.deleteDialog.title'));
      if (ok) deleteChannel({ variables: { channelId: channel._id } });
    } else {
      Alert.alert(
        t('channels.deleteDialog.title'),
        t('channels.deleteDialog.description'),
        [
          {
            text: t('app.cancel'),
            style: 'cancel',
          },
          {
            text: t('app.delete'),
            onPress: () =>
              deleteChannel({ variables: { channelId: channel._id } }),
          },
        ]
      );
    }
  };

  if (channel.room.isUser) {
    actions.push({
      icon: 'user-minus',
      label:
        channel.type === 'course'
          ? t('channels.leaveCourse')
          : t('channels.leave'),
      onPress: () => removeUser({ variables: { documentId: channel._id } }),
    });
  }

  if (channel.permissions.canDelete && channel.type !== 'course') {
    actions.push({
      icon: 'trash',
      label: t('app.delete'),
      onPress: () => doDeleteChannel(),
    });
  }

  if (actions.length < 1) {
    return null;
  }

  return (
    <Menu
      actions={actions}
      Anchor={React.forwardRef(({ onPress }, ref) => (
        <IconButton
          icon={Platform.OS === 'android' ? 'ellipsis-v' : 'ellipsis-h'}
          onPress={onPress}
          ref={ref}
          style={styles.iconButton}
        />
      ))}
    />
  );
}

const styles = StyleSheet.create({
  iconButton: {
    marginRight: Sizes.edge,
  },
});
