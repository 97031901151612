import color from 'color';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AsyncStorage,
  Image,
  InteractionManager,
  Platform,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import SpaceList from '../components/SpaceList';
import AuthContext from '../components/AuthContext';
import {
  Icon,
  IconButton,
  Link,
  Text,
  Title,
  Touchable,
} from '../components/base';
import ErrorNotice from '../components/errors/ErrorNotice';
import ListLoader from '../components/loaders/ListLoader';
import { Colors, Layout, Sizes } from '../constants';
import { auth } from '../lib';

const SPACES = gql`
  query Spaces(
    $orgId: ID
    $mine: Boolean
    $query: String
    $limit: Int
    $page: Int
  ) {
    spaces(
      orgId: $orgId
      mine: $mine
      query: $query
      limit: $limit
      page: $page
    ) {
      nextPage
      spaces {
        _id
        discoverability
        isMember
        name
        permissions {
          canAddSelf
        }
      }
    }
  }
`;

const NoSpaces = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <View
      style={{
        padding: Sizes.content,
        marginHorizontal: 'auto',
        width: Layout.isSmallDevice ? '100%' : Sizes.unit * 178,
      }}
    >
      <View style={{ alignItems: 'center', marginVertical: Sizes.unit * 3 }}>
        <Image
          source={require('../assets/illustrations/find-space.png')}
          style={{ width: 240, height: 240, resizeMode: 'contain' }}
        />
      </View>
      <Title style={{ textAlign: 'center' }}>{t('spaces.findFirst')}</Title>
      <Text style={{ textAlign: 'center', marginBottom: Sizes.unit * 3 }}>
        {t('spaces.spaceIs')}
      </Text>
      <Touchable onPress={() => navigation.navigate('UseInvite')}>
        <View
          style={{
            alignItems: 'center',
            borderColor: Colors.border,
            borderWidth: StyleSheet.hairlineWidth,
            borderRadius: Sizes.radius,
            flexDirection: 'row',
            padding: Sizes.unit * 4,
          }}
        >
          <Icon
            color={Colors.primary}
            name="ticket-alt"
            size={32}
            style={{ marginRight: Sizes.unit * 3 }}
          />
          <Text style={{ fontWeight: 'bold', flex: 1 }}>
            {t('app.useInvite')}
          </Text>
          <Icon
            color={color(Colors.text).alpha(0.5).rgb().string()}
            name="angle-right"
          />
        </View>
      </Touchable>
    </View>
  );
};

export default function SelectSpaceScreen({ route, navigation }) {
  const { setSpace, signOut } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const mine = !!route.params?.mine;

  const onAdd = (space) => setSpace({ spaceId: space._id });

  const { data, fetchMore, loading, ...result } = useQuery(SPACES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { mine: mine },
  });
  const onFetchMore = () =>
    fetchMore({
      variables: {
        page: data.spaces.nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousSpaces = previousResult.spaces;
        const spaces = fetchMoreResult.spaces;
        return {
          spaces: {
            nextPage: spaces.nextPage,
            spaces: [...previousSpaces.spaces, ...spaces.spaces],
            __typename: previousSpaces.__typename,
          },
        };
      },
    });

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{ flexDirection: 'row', marginRight: Sizes.edge }}>
          <IconButton
            icon="sign-out-alt"
            onPress={() => signOut()}
            style={{ marginRight: Sizes.unit * 2 }}
          />
          <IconButton
            color={Colors.white}
            icon="ticket-alt"
            onPress={() => navigation.navigate('UseInvite')}
            style={{ backgroundColor: Colors.primary }}
          />
        </View>
      ),
    });
  });

  return data?.spaces?.spaces?.length === 0 ? (
    <NoSpaces />
  ) : (
    <SpaceList
      {...result}
      data={data}
      onAdd={onAdd}
      onFetchMore={onFetchMore}
    />
  );
}

const styles = StyleSheet.create({
  headerButtons: {
    marginRight: Sizes.edge,
  },
  screen: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
