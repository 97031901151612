import color from 'color';
import { StyleSheet } from 'react-native';

import { Colors, Sizes } from '../../constants';

const loaderStyles = StyleSheet.create({
  animation: {
    backgroundColor: color(Colors.text)
      .alpha(0.3)
      .rgb()
      .string(),
  },
  container: {
    padding: Sizes.content,
  },
  placeholder: {
    marginBottom: Sizes.unit * 6,
  },
  element: {
    backgroundColor: color(Colors.text)
      .alpha(0.1)
      .rgb()
      .string(),
  },
});

export default loaderStyles;
