const firebaseConfig = {
	apiKey: "AIzaSyAJDrxYM35HMcqNI_dL4sJcHdMuhChhFpU",
	authDomain: "papenseapp.firebaseapp.com",
  databaseURL: "https://papenseapp.firebaseio.com",
  projectId: "papenseapp",
  storageBucket: "papenseapp.appspot.com",
  messagingSenderId: "206737016868",
  appId: "1:206737016868:web:522a24d8a764d50e8530b1",
  measurementId: "G-HM2VTCBN9T"
};

export default firebaseConfig;
