import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TextInput, View } from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/client';

import {
  ActivityIndicator,
  BigButton,
  BigField,
  Text,
} from '../components/base';
import AuthContext from '../components/AuthContext';
import { Colors, Sizes } from '../constants';
import ResponsiveModal from '../navigation/ResponsiveModal';

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($invite: ID!) {
    acceptInvite(invite: $invite) {
      _id
      spaces {
        spaces {
          _id
          name
          isMember
        }
      }
    }
  }
`;
const SPACE_BY_INVITE = gql`
  query SpaceByInvite($invite: ID!) {
    spaceByInvite(invite: $invite) {
      _id
      discoverability
      name
    }
  }
`;

export default function InviteScreen({ route }) {
  const { t } = useTranslation();
  const [value, onChangeText] = React.useState('');
  const { setSpace, signOut } = React.useContext(AuthContext);

  const onAccept = (space) => setSpace({ spaceId: space._id });

  const [acceptInvite, { loading: loadingAccept }] = useMutation(
    ACCEPT_INVITE,
    {
      onError: () => alert(t('app.inviteNotFound')),
    }
  );
  const [getSpaceByInvite, { data, loading: loadingSpace }] = useLazyQuery(
    SPACE_BY_INVITE,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  React.useEffect(() => {
    if (value?.length > 0) {
      getSpaceByInvite({ variables: { invite: value } });
    }
  }, [value]);

  return (
    <ResponsiveModal title={t('app.useInvite')}>
      <View
        style={{
          flex: 1,
          padding: Sizes.content,
          justifyContent: 'space-between',
        }}
      >
        <View>
          <BigField
            label={t('app.inviteCode')}
            right={() => loadingSpace && <ActivityIndicator />}
            style={{ marginBottom: Sizes.unit * 2 }}
          >
            <TextInput
              onChangeText={(text) => onChangeText(text)}
              placeholder={t('app.useInvitePlaceholder')}
              value={value}
            />
          </BigField>
          <Text muted style={{ marginBottom: Sizes.unit * 2 }}>
            {t('app.inviteIs')}
          </Text>
          {data?.spaceByInvite && (
            <View
              style={{
                borderColor: Colors.border,
                borderWidth: StyleSheet.hairlineWidth,
                borderRadius: Sizes.radius,
                marginBottom: Sizes.unit * 3,
                padding: Sizes.unit * 3,
              }}
            >
              <Text style={{ marginBottom: Sizes.unit * 1 }}>
                {data.spaceByInvite.name}
              </Text>
              <Text muted>
                {
                  {
                    public: t('spaces.public'),
                    open: t('spaces.open'),
                    closed: t('spaces.manualJoinRequired'),
                  }[data.spaceByInvite.discoverability]
                }
              </Text>
            </View>
          )}
        </View>
        <BigButton
          disabled={!value || value.length < 1 || !data?.spaceByInvite}
          loading={loadingAccept}
          onPress={() =>
            acceptInvite({ variables: { invite: value } }).then(() =>
              onAccept(data.spaceByInvite)
            )
          }
          title={t('app.useInvite')}
        />
      </View>
    </ResponsiveModal>
  );
}
