import color from 'color';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  AvatarImage,
  Icon,
  ListItem,
  Text,
  Touchable,
} from '../../components/base';
import AttachmentList from '../../components/AttachmentList';
import { Colors, Sizes } from '../../constants';
import { dateTime } from '../../lib';

const SmallIconButton = ({ icon, onPress, style, ...props }) => (
  <Touchable onPress={onPress}>
    <View
      {...props}
      style={[
        {
          borderColor: Colors.border,
          borderWidth: StyleSheet.hairlineWidth,
          borderRadius: Sizes.radius,
          padding: Sizes.unit * 2,
        },
        style,
      ]}
    >
      <Icon size={12} name={icon} />
    </View>
  </Touchable>
);

const ArchiveToggle = ({ comment, onToggleArchive, ...props }) =>
  comment.permissions.canArchive ? (
    <SmallIconButton
      {...props}
      icon={comment.isArchived ? 'trash-restore-alt' : 'archive'}
      onPress={() => onToggleArchive(comment)}
    />
  ) : null;

const EditButton = ({ comment, onEdit, ...props }) =>
  comment.permissions.canUpdateBody ? (
    <SmallIconButton {...props} icon="pen" onPress={() => onEdit(comment)} />
  ) : null;

export default function CommentItem({
  comment,
  onEdit,
  onPress,
  onToggleArchive,
}) {
  const selectedBg = color(Colors.secondary).alpha(0.24).rgb().string();

  return (
    <Touchable
      onPress={() => onPress(comment)}
      style={[comment.isSelected && { backgroundColor: selectedBg }]}
      disabled={!comment.selectable}
    >
      <View
        id={comment._id}
        style={{
          paddingHorizontal: Sizes.content,
          paddingVertical: Sizes.unit * 2,
        }}
      >
        {comment.isArchived ? (
          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
            <Text
              muted={comment.isArchived}
              numberOfLines={comment.isArchived ? 1 : undefined}
              style={{ flex: 1, marginRight: Sizes.unit * 2 }}
            >
              {comment.body}
            </Text>
            <ArchiveToggle
              comment={comment}
              onToggleArchive={onToggleArchive}
            />
          </View>
        ) : (
          <Text style={{ marginBottom: Sizes.unit * 2 }}>{comment.body}</Text>
        )}
        {!comment.isArchived && (
          <React.Fragment>
            {comment.attachments?.length > 0 && (
              <AttachmentList
                attachments={comment.attachments}
                style={{ marginBottom: Sizes.unit * 2 }}
              />
            )}
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              <AvatarImage
                size={24}
                source={{ uri: comment.user.pictureUrl }}
                style={{ marginRight: Sizes.unit * 2 }}
              />
              <Text muted={true} style={{ flex: 1 }}>
                {comment.user.fullName}
              </Text>
              <Text muted={true} style={{ marginRight: Sizes.unit * 2 }}>
                {dateTime.formatTiny(comment.createdAt)}
              </Text>
              <ArchiveToggle
                comment={comment}
                onToggleArchive={onToggleArchive}
                style={{ marginRight: Sizes.unit * 2 }}
              />
              <EditButton comment={comment} onEdit={onEdit} />
            </View>
          </React.Fragment>
        )}
      </View>
    </Touchable>
  );
}
