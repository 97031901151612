import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import FOLDER from './FOLDER.js';
import uploadFile from './uploadFile';
import { IconButton, Menu } from '../../components/base';
import { Colors, Sizes } from '../../constants';
import { fileUtils } from '../../lib';

const DELETE_FILE = gql`
  mutation DeleteFile($path: String!, $treeId: ID!) {
    deleteFile(path: $path, treeId: $treeId) {
      _id
      path
      treeId
    }
  }
`;

export default function HeaderRight({
  onPick,
  onToggleSelectable,
  path,
  pick,
  selectable,
  selectedFiles,
  treeId,
}) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const actions = [
    {
      icon: 'folder-plus',
      label: t('files.createFolder'),
      onPress: () => navigation.navigate('CreateFolder', { path, treeId }),
    },
    {
      icon: 'cloud-upload-alt',
      label: t('files.uploadFile'),
      onPress: () => uploadFile({ path, treeId }),
    },
  ];

  const [deleteFile, { loading }] = useMutation(DELETE_FILE, {
    update: (cache, { data: { deleteFile } }) => {
      const parentPath = fileUtils.parentPath(deleteFile.path);
      const { folder } = cache.readQuery({
        query: FOLDER,
        variables: { path: parentPath, treeId: deleteFile.treeId },
      });
      cache.writeQuery({
        query: FOLDER,
        variables: { path: parentPath, treeId: deleteFile.treeId },
        data: {
          folder: {
            ...folder,
            files: folder.files.filter((file) => file._id !== deleteFile._id),
          },
        },
      });
    },
  });
  const onDelete = () =>
    selectedFiles.forEach((file) =>
      deleteFile({
        variables: { treeId: file.treeId, path: file.path },
      }).catch((error) => {
        if (error.message.includes('hasChildren')) {
          alert(t('errors.hasChildren'));
        } else {
          console.error(error);
        }
      })
    );

  return (
    <View style={{ flexDirection: 'row' }}>
      {selectable &&
        !pick &&
        !selectedFiles.find((selected) => !selected.permissions.canDelete) && (
          <IconButton
            icon="trash-alt"
            onPress={onDelete}
            style={styles.iconButton}
          />
        )}
      <IconButton
        icon={selectable ? 'check' : 'check-square'}
        onPress={pick ? onPick : onToggleSelectable}
        style={styles.iconButton}
      />
      {!selectable && Platform.OS !== 'android' && (
        <Menu
          actions={actions}
          Anchor={React.forwardRef(({ onPress }, ref) => (
            <IconButton
              color={Colors.white}
              icon="plus"
              onPress={onPress}
              ref={ref}
              style={[{ backgroundColor: Colors.primary }, styles.iconButton]}
            />
          ))}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  iconButton: {
    marginRight: Sizes.edge,
  },
});
