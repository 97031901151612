/**
 * Our official color palette can be found at:
 * https://coolors.co/dd7255-07beb8-404463-ffffff
 */
const NAMED_COLORS = {
  davysGray: '#595959',
  greenYellowCrayola: '#fbe89d',
  independence: '#404463',
  lightGray: '#d6d6d6',
  platinum: '#e2e2e2',
  quickSilver: '#a1a1a1',
  terraCotta: '#dd7255',
  tiffanyBlue: '#07beb8',
  white: '#ffffff',
};

const THEME_COLORS = {
  border: NAMED_COLORS.platinum,
  disabled: NAMED_COLORS.lightGray,
  disabledContrast: NAMED_COLORS.quickSilver,
  light: NAMED_COLORS.cultured,
  muted: NAMED_COLORS.silver,
  neutral: NAMED_COLORS.lightGray,
  neutralContrast: NAMED_COLORS.davysGray,
  primary: NAMED_COLORS.terraCotta,
  secondary: NAMED_COLORS.tiffanyBlue,
  text: NAMED_COLORS.independence,
};

export default {
  ...NAMED_COLORS,
  ...THEME_COLORS,
};
