import * as React from 'react';

import Text from './Text';
import Colors from '../../constants/Colors';
import Sizes from '../../constants/Sizes';

export default function Title(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontSize: Sizes.font * 1.25,
          fontWeight: '700',
          marginBottom: Sizes.unit * 2,
        },
        props.style,
      ]}
    />
  );
}
