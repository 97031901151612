import gql from 'graphql-tag';
import * as WebBrowser from 'expo-web-browser';

import client from './graphqlClient';

export const parentPath = (path) =>
  path.split('/').length > 2
    ? path
        .split('/')
        .slice(0, path.split('/').length - 1)
        .join('/')
    : '/';

export const download = ({ path, treeId }) => {
  const DOWNLOAD_URL = gql`
    query DownloadUrl($path: String!, $treeId: ID!) {
      file(path: $path, treeId: $treeId) {
        _id
        downloadUrl
      }
    }
  `;

  client
    .query({
      query: DOWNLOAD_URL,
      variables: {
        path: path,
        treeId: treeId,
      },
      fetchPolicy: 'network-only',
    })
    .then(({ data: { file } }) =>
      WebBrowser.openBrowserAsync(file.downloadUrl)
    );
};
