import color from 'color';
import * as React from 'react';
import { Text as RNText } from 'react-native';

import { Colors, Sizes } from '../../constants';

export default function Text({ muted, style, ...props }) {
  return (
    <RNText
      {...props}
      style={[
        {
          color: color(Colors.text)
            .alpha(muted ? 0.5 : 1)
            .rgb()
            .string(),
          fontSize: Sizes.font,
        },
        style,
      ]}
    />
  );
}
