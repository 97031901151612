import { Formik, useFormikContext } from 'formik';
import gql from 'graphql-tag';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';

import POST from './POST';
import {
  ActivityIndicator,
  IconButton,
  TextInput,
} from '../../components/base';
import AttachmentList from '../../components/AttachmentList';
import SpaceContext from '../../components/SpaceContext';
import { Layout, Sizes } from '../../constants';

const UPDATE_COMMENT_BODY = gql`
  mutation UpdateCommentBody($commentId: ID!, $body: String!) {
    updateCommentBody(commentId: $commentId, body: $body) {
      _id
      body
    }
  }
`;

const CREATE_COMMENT = gql`
  mutation CreateComment($input: CommentInput!) {
    createComment(input: $input) {
      _id
      attachments {
        _id
        name
        path
        treeId
      }
      body
      createdAt
      isArchived
      permissions {
        canArchive
        canDelete
        canUpdateBody
      }
      post {
        _id
        last {
          _id
          body
          user {
            _id
            fullName
            pictureUrl
          }
        }
      }
      user {
        _id
        fullName
        pictureUrl
      }
    }
  }
`;

const SetComment = ({ editableComment }) => {
  const { setFieldValue } = useFormikContext();
  React.useEffect(() => {
    if (editableComment?.body) {
      setFieldValue('body', editableComment.body);
    }
  }, [editableComment]);
  return null;
};

export default function CreateComment({
  editableComment,
  onCommentEdited,
  post,
}) {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute();
  const { space } = React.useContext(SpaceContext);
  const bodyInputRef = React.useRef(null);
  const postId = post._id;
  // Limit to 10 attachments
  const picks = route.params?.picks?.slice(0, 9);

  const [createComment, { loading: loadingCreate }] = useMutation(
    CREATE_COMMENT,
    {
      update: (cache, { data: { createComment } }) => {
        const { post } = cache.readQuery({
          query: POST,
          variables: { postId: postId },
        });
        cache.writeQuery({
          query: POST,
          variables: { postId: postId },
          data: {
            post: {
              ...post,
              comments: {
                ...post.comments,
                comments: [createComment].concat(post.comments.comments),
              },
            },
          },
        });
      },
    }
  );
  const [updateCommentBody, { loading: loadingUpdate }] = useMutation(
    UPDATE_COMMENT_BODY
  );
  const loading = loadingCreate || loadingUpdate;

  const onSubmit = (values, { resetForm }) => {
    const attachments = picks?.map(({ path, treeId }) => ({
      path,
      treeId,
    }));

    if (editableComment) {
      updateCommentBody({
        variables: { commentId: editableComment._id, body: values.body },
      }).then(() => {
        onCommentEdited(editableComment);
        resetForm();
        bodyInputRef.current.blur();
      });
    } else {
      createComment({
        variables: {
          input: {
            attachments: attachments,
            body: values.body,
            postId: post._id,
          },
        },
      }).then(() => {
        resetForm();
        navigation.setParams({ picks: null });
        bodyInputRef.current.blur();
      });
    }
  };

  const numberOfLines = Layout.isSmallDevice ? 2 : 4;

  return (
    <Formik
      initialValues={{ body: '' }}
      onSubmit={onSubmit}
      validationSchema={yup.object().shape({
        body: yup.string().required().max(3000),
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        touched,
        values,
      }) => (
        <View style={styles.container}>
          <SetComment editableComment={editableComment} />
          {picks && (
            <AttachmentList
              attachments={picks}
              style={{ margin: Sizes.unit * 2 }}
            />
          )}
          <TextInput
            disabledUnderlineAndroid={true}
            ref={bodyInputRef}
            maxLength={3000}
            multiline={true}
            numberOfLines={numberOfLines}
            onBlur={handleBlur('body')}
            onChangeText={handleChange('body')}
            placeholder={t('comments.inputs.bodyPlaceholder')}
            style={styles.input}
            value={values.body}
          />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            {editableComment ? (
              <IconButton
                icon="times"
                onPress={() => {
                  setFieldValue('body', '');
                  onCommentEdited();
                }}
                style={styles.button}
              />
            ) : (
              <IconButton
                icon="paperclip"
                onPress={() =>
                  navigation.navigate('Folder', {
                    callback: route.key,
                    path: '/',
                    pick: true,
                    selectable: true,
                    treeId: space._id,
                  })
                }
                style={styles.button}
              />
            )}
            {loading ? (
              <ActivityIndicator style={styles.activity} />
            ) : (
              <IconButton
                disabled={!isValid}
                icon="paper-plane"
                onPress={handleSubmit}
                style={styles.button}
              />
            )}
          </View>
        </View>
      )}
    </Formik>
  );
}

const styles = StyleSheet.create({
  activity: {
    padding: 9,
  },
  container: {
    paddingHorizontal: Sizes.edge,
  },
  input: {
    flex: 1,
    maxHeight: Sizes.unit * 50,
  },
  button: {
    backgroundColor: 'transparent',
    marginLeft: Sizes.unit * 2,
  },
});
