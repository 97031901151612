import * as React from 'react';
import { View } from 'react-native';

import { ActivityIndicator } from '../components/base';

export default function LoadingScreen(props) {
  return (
    <View style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
      <ActivityIndicator />
    </View>
  );
}
