import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native';

const googleIcon = require('../../assets/images/google.png');

export default function GoogleSignInButton({ children, style, ...props }) {
  const { t } = useTranslation();
  const Touchable =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
  return (
    <Touchable {...props} useForeground={true}>
      <View style={[styles.content, style]}>
        <Image source={googleIcon} style={styles.icon} />
        <Text style={styles.text}>{t('auth.google')}</Text>
      </View>
    </Touchable>
  );
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: '#4285f4',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    borderRadius: 4,
  },
  icon: { width: 42, height: 42 },
  text: { color: 'white', marginLeft: 10, fontSize: 16, fontWeight: 'bold' },
});
