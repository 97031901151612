import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { useMutation, useQuery } from '@apollo/client';
import Clipboard from '@react-native-community/clipboard';

import { BigButton, BigField, Link, Text } from '../components/base';
import { Sizes } from '../constants';
import ResponsiveModal from '../navigation/ResponsiveModal';
import SPACE from '../navigation/SPACE';

const CHANGE_INVITE = gql`
  mutation ChangeInvite($spaceId: ID!) {
    changeInvite(spaceId: $spaceId) {
      _id
      invite
    }
  }
`;

export default function InviteScreen({ route }) {
  const { t } = useTranslation();
  const spaceId = route.params.spaceId;

  const { data } = useQuery(SPACE, { variables: { spaceId: spaceId } });
  const [changeInvite] = useMutation(CHANGE_INVITE);
  const copy = () => {
    const { invite } = data.space;
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(invite);
    } else {
      Clipboard.setString(invite);
    }
  };

  return (
    <ResponsiveModal title={t('app.invitePeople')}>
      {data.space && (
        <View style={{ padding: Sizes.content }}>
          <Text style={{ marginBottom: Sizes.unit * 3 }}>
            {t('app.inviteDescription')}
          </Text>
          <BigField
            label={t('app.inviteCode')}
            right={() => <BigButton onPress={copy} title={t('app.copy')} />}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text>{data.space.invite}</Text>
            </View>
          </BigField>
          <Text muted={true} style={{ marginBottom: Sizes.unit * 2 }}>
            {t('app.replaceInvite')}
          </Text>
          <Link
            onPress={() => changeInvite({ variables: { spaceId: spaceId } })}
          >
            {t('app.replace')}
          </Link>
        </View>
      )}
    </ResponsiveModal>
  );
}
