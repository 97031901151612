import gql from 'graphql-tag';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMutation } from '@apollo/client';

import POST from './POST';
import { ActivityIndicator, IconButton, Text } from '../../components/base';
import { Sizes } from '../../constants';

const DELETE_COMMENT = gql`
  mutation DeleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      _id
    }
  }
`;

export default function CommentActions({
  post,
  resetSelected,
  selectedComments,
}) {
  const postId = post._id;

  const [deleteComment, { loading }] = useMutation(DELETE_COMMENT, {
    update: (cache, { data: { deleteComment } }) => {
      const { post } = cache.readQuery({
        query: POST,
        variables: { postId },
      });
      cache.writeQuery({
        query: POST,
        variables: { postId },
        data: {
          post: {
            ...post,
            comments: {
              ...post.comments,
              comments: post.comments.comments.filter(
                (comment) => comment._id !== deleteComment._id
              ),
            },
          },
        },
      });
    },
    onCompleted: () => resetSelected(),
  });

  const onDelete = () =>
    selectedComments.forEach((comment) =>
      deleteComment({ variables: { commentId: comment._id } })
    );

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <IconButton icon="times" onPress={() => resetSelected()} />
        <Text style={styles.counter}>{selectedComments.length}</Text>
      </View>
      {loading ? (
        <ActivityIndicator style={styles.activity} />
      ) : (
        <React.Fragment>
          {!selectedComments.find(
            (selected) => !selected.permissions.canDelete
          ) && <IconButton icon="trash-alt" onPress={onDelete} />}
        </React.Fragment>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  activity: {
    padding: Sizes.unit * 2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Sizes.edge,
    minHeight: Sizes.unit * 15,
  },
  counter: {
    fontSize: Sizes.font * 1.5,
    fontWeight: 'bold',
    marginLeft: Sizes.unit * 5,
  },
  left: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});
