import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { AvatarImage, ListItem } from './base';
import EmptyState from './errors/EmptyState';
import ListLoader from './loaders/ListLoader';
import { Sizes } from '../constants';

const UserList = ({ loading, onFetchMore, spaceId, users }) => {
  const navigation = useNavigation();

  const fetchMore = () => {
    if (users.nextPage) {
      onFetchMore();
    }
  };

  const ListEmptyComponent = () => {
    if (loading) {
      return (
        <View style={ListItem.containerStyle}>
          <ListLoader />
        </View>
      );
    } else {
      return <EmptyState onUpdate={() => refetch()} />;
    }
  };

  const renderItem = ({ item }) => (
    <ListItem
      id={item._id}
      left={() => (
        <AvatarImage
          size={36}
          source={{ uri: item.pictureUrl }}
          style={styles.avatar}
        />
      )}
      onPress={() =>
        navigation.navigate('UserProfile', {
          userId: item._id,
          spaceId: spaceId,
        })
      }
      title={item.fullName}
    />
  );

  return (
    <FlatList
      data={users?.users}
      keyExtractor={(item) => item._id}
      ListEmptyComponent={ListEmptyComponent}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.5}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  avatar: {
    marginRight: Sizes.unit * 5,
  },
});

export default UserList;
