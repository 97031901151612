import color from 'color';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import Touchable from './Touchable';
import Text from './Text';
import { Colors, Layout, Sizes } from '../../constants';

export default function ListItem({ disabled = false, ...props }) {
  const Content = ({ disabled }) => (
    <React.Fragment>
      {props.left && props.left()}
      <View style={styles.textContainer}>
        {props.title && (
          <Text
            muted={disabled}
            numberOfLines={1}
            style={[styles.title, props.titleStyle]}
          >
            {props.title}
          </Text>
        )}
        {props.description &&
          (typeof props.description === 'function' ? (
            props.description()
          ) : (
            <Text
              muted={true}
              numberOfLines={props.descriptionNumberOfLines || 2}
              style={[styles.description, props.descriptionStyle]}
            >
              {props.description}
            </Text>
          ))}
      </View>
      {props.right && props.right()}
    </React.Fragment>
  );

  return (props.onPress || props.onLongPress) && !disabled ? (
    <Touchable
      {...props}
      pressColor={color('black').alpha(0.1).rgb().string()}
      style={[styles.container, props.style]}
    >
      <Content />
    </Touchable>
  ) : (
    <View style={[styles.container, props.style]}>
      <Content disabled={disabled} />
    </View>
  );
}
ListItem.containerStyle = Layout.isSmallDevice
  ? { flex: 1, width: '100%' }
  : {
      flex: 1,
      marginHorizontal: 'auto',
      marginTop: Sizes.contentTop,
      maxWidth: Sizes.maxWidth,
      overflow: 'hidden',
      width: '100%',
    };

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flexDirection: 'row',
    paddingHorizontal: Sizes.content,
    paddingVertical: Sizes.unit * 3,
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    fontSize: Sizes.font * 1.1,
    marginBottom: Sizes.unit,
  },
});
