import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Shine,
} from 'rn-placeholder';

import styles from './loaderStyles';

const MediaLoader = props => (
  <View style={styles.container}>
    <Placeholder
      Animation={props => <Shine {...props} style={styles.animation} />}
      Left={props => (
        <PlaceholderMedia
          {...props}
          isRound={true}
          style={[props.style, styles.element]}
        />
      )}
      style={styles.placeholder}
    >
      <PlaceholderLine width={40} style={styles.element} />
      <PlaceholderLine width={80} style={styles.element} />
    </Placeholder>
    <Placeholder
      Animation={props => <Shine {...props} style={styles.animation} />}
      Left={props => (
        <PlaceholderMedia
          {...props}
          isRound={true}
          style={[props.style, styles.element]}
        />
      )}
      style={styles.placeholder}
    >
      <PlaceholderLine width={20} style={styles.element} />
      <PlaceholderLine width={70} style={styles.element} />
    </Placeholder>
    <Placeholder
      Animation={props => <Shine {...props} style={styles.animation} />}
      Left={props => (
        <PlaceholderMedia
          {...props}
          isRound={true}
          style={[props.style, styles.element]}
        />
      )}
      style={(styles.placeholder, { marginBottom: 0 })}
    >
      <PlaceholderLine width={30} style={styles.element} />
      <PlaceholderLine width={60} style={styles.element} />
    </Placeholder>
  </View>
);

export default MediaLoader;
