import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';

import Hamburger from './Hamburger';
import { Icon } from '../components/base';
import CourseContext from '../components/CourseContext';
import { Colors, Layout } from '../constants';
import ChannelScreen from '../screens/ChannelScreen';
import FolderScreen from '../screens/FolderScreen';

const Tab = createBottomTabNavigator();
const ChannelStack = createStackNavigator();
const FolderStack = createStackNavigator();

const headerLeft = Layout.isSmallDevice
  ? (props) => <Hamburger {...props} />
  : undefined;

export default function CourseBottomTab({ route }) {
  const { t } = useTranslation();
  const { channelId, defaultTitle } = route.params;

  return (
    <CourseContext.Provider value={{ channelId, defaultTitle }}>
      <Tab.Navigator tabBarOptions={{ activeTintColor: Colors.primary }}>
        <Tab.Screen
          name="Channel"
          component={CourseChannel}
          options={{
            tabBarLabel: t('app.posts'),
            tabBarIcon: ({ color, size }) => (
              <Icon name="comment-alt" color={color} size={size} solid />
            ),
          }}
        />
        <Tab.Screen
          name="Folder"
          component={CourseFolder}
          options={{
            tabBarLabel: t('app.files'),
            tabBarIcon: ({ color, size }) => (
              <Icon name="folder" color={color} size={size} solid />
            ),
          }}
        />
      </Tab.Navigator>
    </CourseContext.Provider>
  );
}

function CourseChannel({ route }) {
  const { t } = useTranslation();

  return (
    <ChannelStack.Navigator>
      <ChannelStack.Screen
        name="Channel"
        component={ChannelScreen}
        options={{
          headerLeft: headerLeft,
          title: t('app.loading'),
        }}
      />
    </ChannelStack.Navigator>
  );
}

function CourseFolder({ route }) {
  const { t } = useTranslation();

  return (
    <FolderStack.Navigator>
      <FolderStack.Screen
        name="Folder"
        component={FolderScreen}
        initialParams={{ noModal: true }}
        options={{
          headerLeft: headerLeft,
          title: t('app.files'),
        }}
      />
    </FolderStack.Navigator>
  );
}
