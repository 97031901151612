import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Image, StyleSheet, View } from 'react-native';

import { ActivityIndicator, AvatarImage, ListItem, Text } from './base';
import EmptyState from './errors/EmptyState';
import MediaLoader from './loaders/MediaLoader';
import { Colors, Sizes } from '../constants';
import { dateTime } from '../lib';

export default function PostList({
  data,
  error,
  loading,
  networkStatus,
  onFetchMore,
  onItemPress,
  refetch,
}) {
  const { t } = useTranslation();

  const fetchMore = () => {
    if (data.nextPage) {
      onFetchMore(data.nextPage);
    }
  };

  const renderEmpty = () => {
    if (networkStatus === 1) {
      return (
        <View style={ListItem.containerStyle}>
          <MediaLoader />
        </View>
      );
    } else {
      return <EmptyState onUpdate={() => refetch()} />;
    }
  };

  const renderFooter = () =>
    networkStatus === 3 ? <ActivityIndicator /> : null;

  const renderDescription = ({ body, fullName, pictureUrl }) => (
    <View style={{ flexDirection: 'row' }}>
      <AvatarImage
        size={Sizes.unit * 6}
        source={{ uri: pictureUrl }}
        style={styles.avatar}
      />
      <Text muted={true} numberOfLines={2} style={{ marginTop: 2 }}>
        {`${fullName} — ` + body}
      </Text>
    </View>
  );

  const renderItem = ({ item }) => (
    <ListItem
      id={item._id}
      description={() =>
        item.last
          ? renderDescription({
              body: item.last.body,
              fullName: item.last.user.fullName,
              pictureUrl: item.last.user.pictureUrl,
            })
          : renderDescription({
              body: t('posts.wasJustCreated'),
              fullName: item.user.fullName,
              pictureUrl: item.user.pictureUrl,
            })
      }
      onPress={() => onItemPress(item)}
      right={() => (
        <View style={styles.right}>
          <Text muted={true} style={styles.info}>
            {dateTime.formatTiny(item.lastCommentedAt)}
          </Text>
          {item.toRead && <Text style={styles.toRead}>⬤</Text>}
        </View>
      )}
      title={item.title}
      titleStyle={styles.title}
    />
  );

  return (
    <FlatList
      contentContainerStyle={ListItem.containerStyle}
      data={data?.posts}
      ListEmptyComponent={renderEmpty}
      ListFooterComponent={renderFooter}
      keyExtractor={(item) => item._id}
      renderItem={renderItem}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.5}
      onRefresh={() => refetch()}
      refreshing={networkStatus === 4}
    />
  );
}

const styles = StyleSheet.create({
  avatar: {
    marginRight: Sizes.unit * 2,
  },
  divider: {
    marginLeft: Sizes.unit * 18,
  },
  empty: {
    textAlign: 'center',
  },
  info: {
    fontSize: Sizes.font * 0.7,
  },
  right: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingLeft: Sizes.unit * 2,
  },
  toRead: {
    color: Colors.primary,
  },
});
