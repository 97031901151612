import * as firebase from 'firebase/app';
import { AsyncStorage } from 'react-native';
import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import graphqlCache from './graphqlCache';
import { appConfig } from '../config';

const httpLink = createHttpLink({
  uri: appConfig.serverUrl + '/graphql',
});

const authLink = setContext(
  () =>
    new Promise((success, fail) => {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) =>
            success({
              headers: {
                Authorization: token ? `Bearer ${token}` : '',
              },
            })
          )
          .catch((error) => fail(error));
      } else {
        AsyncStorage.getItem('idToken')
          .then((token) =>
            success({
              headers: { Authorization: token ? `Bearer ${token}` : '' },
            })
          )
          .catch((error) => fail(error));
      }
    })
);

const graphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: graphqlCache,
});

export default graphqlClient;
