import * as React from 'react';
import { View } from 'react-native';

import Text from './Text';
import { Colors, Sizes } from '../../constants';

export default function BigField({ children, label, right, style, ...props }) {
  return (
    <View
      {...props}
      style={[
        style,
        {
          alignItems: 'center',
          borderColor: Colors.border,
          borderWidth: 2,
          borderRadius: Sizes.radius,
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: Sizes.unit * 2,
        },
      ]}
    >
      <View>
        {label && (
          <Text
            style={{
              color: Colors.primary,
              fontSize: Sizes.font * 0.75,
              fontWeight: '700',
              marginBottom: Sizes.unit,
              textTransform: 'uppercase',
            }}
          >
            {label}
          </Text>
        )}
        {children}
      </View>
      {right && right()}
    </View>
  );
}
