import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InteractionManager, Platform, StyleSheet, View } from 'react-native';
import { useLazyQuery } from '@apollo/client';

import CreateComment from './CreateComment';
import PostMore from './PostMore';
import List from './List';
import POST from './POST';
import Toolbar from './Toolbar';
import { Divider, IconButton } from '../../components/base';
import ErrorState from '../../components/errors/ErrorState';
import MediaLoader from '../../components/loaders/MediaLoader';
import { Layout, Sizes } from '../../constants';
import { graphqlClient } from '../../lib';
import ResponsiveModal from '../../navigation/ResponsiveModal';

const MARK_AS_READ_FOR_POST = gql`
  mutation MarkAsReadForPost($postId: ID!) {
    markAsReadForPost(postId: $postId) {
      _id
      channel {
        _id
        unreadCount
      }
      post {
        _id
        toRead
      }
    }
  }
`;

const MORE_COMMENTS = gql`
  query Post($postId: ID!, $commentPage: Int) {
    post(postId: $postId) {
      comments(page: $commentPage) {
        comments {
          _id
          attachments {
            _id
            name
            path
            treeId
          }
          body
          createdAt
          isArchived
          permissions {
            canArchive
            canDelete
            canUpdateBody
          }
          user {
            _id
            fullName
            pictureUrl
          }
        }
        nextPage
      }
    }
  }
`;

export default function PostScreen(props) {
  const { t } = useTranslation();
  const postId = props.route.params?.postId;
  const [editableComment, setEditableComment] = useState(null);
  const [selectable, setSelectable] = useState(false);
  const [selectedComments, setSelectedComments] = useState([]);

  const onCommentEdited = () => setEditableComment(null);
  const onEditComment = (comment) => setEditableComment(comment);
  const onPressItem = (comment) => {
    if (selectedComments.find((selected) => selected._id === comment._id)) {
      setSelectedComments(
        selectedComments.filter((selected) => selected._id !== comment._id)
      );
    } else {
      setSelectedComments([...selectedComments, comment]);
    }
  };
  const resetSelected = () => {
    setSelectedComments([]);
    setSelectable(false);
  };

  const [getPost, { data, fetchMore, ...result }] = useLazyQuery(POST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  const post = data?.post;

  const HeaderRight = ({ selectable }) => (
    <View style={styles.headerButtons}>
      {post && !selectable && (
        <IconButton
          icon="check-square"
          onPress={() => setSelectable(true)}
          style={{ marginRight: Sizes.unit * 2 }}
        />
      )}
      <PostMore post={post} />
    </View>
  );

  useEffect(() => {
    if (post) {
      props.navigation.setOptions({
        headerRight: () => <HeaderRight selectable={selectable} />,
        title: selectable ? t('posts.selectComments') : post.title,
        headerTitleContainerStyle: { maxWidth: Layout.window.width * 0.6 },
      });

      if (post.toRead) {
        graphqlClient.mutate({
          mutation: MARK_AS_READ_FOR_POST,
          variables: { postId: post._id },
        });
      }
    } else {
      getPost({ variables: { postId } });
    }
  }, [post, selectable]);

  const onFetchMore = () =>
    fetchMore({
      query: MORE_COMMENTS,
      variables: {
        postId,
        commentPage: post.comments.nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousPost = previousResult.post;
        const comments = fetchMoreResult.post.comments;
        return {
          post: {
            ...previousPost,
            comments: {
              ...previousPost.comments,
              nextPage: comments.nextPage,
              comments: [
                ...previousPost.comments.comments,
                ...comments.comments,
              ],
            },
          },
        };
      },
    });

  return (
    <ResponsiveModal
      title={
        selectable ? t('posts.selectComments') : post?.title || t('app.post')
      }
      headerRight={() => <HeaderRight selectable={selectable} />}
    >
      <List
        {...result}
        data={data}
        onEditComment={onEditComment}
        onFetchMore={onFetchMore}
        onPressItem={onPressItem}
        selectable={selectable}
        selectedComments={selectedComments}
      />
      {post && (
        <Toolbar
          editableComment={editableComment}
          onCommentEdited={onCommentEdited}
          post={post}
          selectable={selectable}
          selectedComments={selectedComments}
          resetSelected={resetSelected}
        />
      )}
    </ResponsiveModal>
  );
}

const styles = StyleSheet.create({
  headerButtons: {
    flexDirection: 'row',
    marginRight: Sizes.edge,
  },
  screen: {
    flex: 1,
  },
});
