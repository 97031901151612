import { InMemoryCache, makeVar } from '@apollo/client';

export const uploads = makeVar([]);

const graphqlCache = new InMemoryCache({
  typePolicies: {
    FolderConnection: {
      fields: {
        uploads: {
          read(_, { variables }) {
            return uploads().filter(
              (upload) =>
                upload.path.replace(upload.name, '') === variables.path &&
                upload.treeId === variables.treeId
            );
          },
        },
      },
    },
  },
});

export default graphqlCache;
