import * as firebase from 'firebase/app';

import { appConfig } from '../config';

export const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  try {
    const { user } = await firebase.auth().signInWithPopup(provider);
    return user.getIdToken();
  } catch ({ code, message }) {
    if (code !== 'auth/popup-closed-by-user') {
      console.error(message);
      alert(`Google login error [${code}]: ${message}`);
    }
  }
};
