import React from 'react';
import {
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import { Colors, Layout, Sizes } from '../constants';
import { Button, IconButton, Title } from '../components/base';

export default function ResponsiveModal({
  children,
  disabled,
  headerRight,
  onClose,
  title,
}) {
  const navigation = useNavigation();
  const goBack = () => navigation.goBack();
  const onCloseAction = onClose || goBack;
  return Layout.isSmallDevice || disabled ? (
    children
  ) : (
    <TouchableWithoutFeedback onPress={onCloseAction}>
      <View style={styles.container}>
        <TouchableWithoutFeedback>
          <View style={styles.content}>
            <View style={styles.header}>
              <IconButton
                icon="times"
                onPress={onCloseAction}
                style={styles.closeButton}
              />
              <Title style={styles.headerTitle}>{title}</Title>
              <View style={styles.headerRight}>
                {headerRight && headerRight()}
              </View>
            </View>
            {children}
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  closeButton: {
    marginRight: Sizes.unit * 4,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: Sizes.edge,
  },
  content: {
    borderRadius: Sizes.radius,
    backgroundColor: Colors.white,
    width: Sizes.unit * 240,
    maxHeight: Layout.window.height - Sizes.content,
    paddingVertical: Sizes.content,
    width: Math.min(Layout.window.width - Sizes.content, Sizes.unit * 240),
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: Sizes.unit * 4,
    paddingHorizontal: Sizes.content,
  },
  headerRight: {
    flexDirection: 'row',
  },
  headerTitle: {
    marginBottom: 0,
    flex: 1,
  },
});
