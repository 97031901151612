import * as React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';

import { Colors, Sizes } from '../../constants';

export default function Icon({ color = Colors.text, ...props }) {
  return (
    <FontAwesome5 color={color} size={props.size || Sizes.icon} {...props} />
  );
}
