import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useQuery } from '@apollo/client';

import UserList from '../components/UserList';
import ErrorState from '../components/errors/ErrorState';
import ListLoader from '../components/loaders/ListLoader';
import ResponsiveModal from '../navigation/ResponsiveModal';

const USERS = gql`
  query Users(
    $channelId: ID
    $query: String
    $spaceId: ID!
    $limit: Int
    $page: Int
  ) {
    users(
      channelId: $channelId
      query: $query
      spaceId: $spaceId
      limit: $limit
      page: $page
    ) {
      nextPage
      users {
        _id
        fullName
        pictureUrl
      }
    }
  }
`;

const SpaceMembersScreen = (props) => {
  const { t } = useTranslation();
  const spaceId = props.route.params?.spaceId;

  const { data, ...result } = useQuery(USERS, {
    variables: { spaceId },
    fetchPolicy: 'cache-and-network',
  });

  const users = data && data.users;

  const fetchMoreUsers = () =>
    fetchMore({
      query: USERS,
      variables: {
        spaceId,
        page: users.nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousUsers = previousResult.users;
        const users = fetchMoreResult.users;
        return {
          users: {
            ...previousUsers,
            nextPage: users.nextPage,
            users: [...previousUsers.users, ...users.users],
          },
        };
      },
    });

  return (
    <ResponsiveModal title={t('app.allMembers')}>
      <UserList
        {...result}
        onFetchMore={fetchMoreUsers}
        spaceId={spaceId}
        users={users}
      />
    </ResponsiveModal>
  );
};

export default SpaceMembersScreen;
