import * as React from 'react';
import { View } from 'react-native';

import { Colors } from '../../constants';

export default function Divider(props) {
  const style = {
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
  };
  return <View {...props} style={[style, props.style]}></View>;
}
