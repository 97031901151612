import React, { createRef, useEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Translation, useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';

import CHANNEL_WITH_POSTS from './CHANNEL_WITH_POSTS';
import CreatePostFAB from './CreatePostFAB';
import HeaderRight from './HeaderRight';
import JoinButton from './JoinButton';
import CourseContext from '../../components/CourseContext';
import PostList from '../../components/PostList';
import EmptyState from '../../components/errors/EmptyState';
import ErrorState from '../../components/errors/ErrorState';
import MediaLoader from '../../components/loaders/MediaLoader';
import { Layout } from '../../constants';

export default function ChannelScreen(props) {
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState();
  const courseContext = React.useContext(CourseContext);
  const params = props.route.params;
  const channelId = params?.channelId || courseContext?.channelId;

  const { data, fetchMore, ...result } = useQuery(CHANNEL_WITH_POSTS, {
    fetchPolicy: 'cache-and-network',
    variables: { channelId, limit: 20 },
  });

  const channel = data && data.channel;

  useEffect(() => {
    if (channel) {
      props.navigation.setOptions({
        headerRight: () => <HeaderRight channel={channel} />,
        headerTitleContainerStyle: { maxWidth: Layout.window.width * 0.6 },
        title: channel.name,
      });
    }
  }, [channel]);

  const onFetchMore = () => {
    fetchMore({
      query: MORE_POSTS,
      variables: {
        channelId: channelId,
        limit: 20,
        page: channel.posts.nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!previousResult || !fetchMoreResult) return previousResult;
        if (previousResult.channel._id !== fetchMoreResult.channel._id)
          return previousResult;

        const previousChannel = previousResult.channel;
        const { posts } = fetchMoreResult.channel;

        return {
          channel: {
            ...previousChannel,
            posts: {
              ...previousChannel.posts,
              nextPage: posts.nextPage,
              posts: [...previousChannel.posts.posts, ...posts.posts],
            },
          },
        };
      },
    });
  };

  return (
    <View style={styles.screen}>
      <PostList
        {...result}
        onFetchMore={onFetchMore}
        onItemPress={item =>
          props.navigation.navigate('Post', { postId: item._id })
        }
        data={channel?.posts}
      />
      {Platform.OS === 'android' && channel && channel.room.isUser && (
        <CreatePostFAB channel={channel} />
      )}
      {channel && !channel.room.isUser && <JoinButton channel={channel} />}
    </View>
  );
}

const styles = StyleSheet.create({
  empty: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    position: 'absolute',
  },
  screen: {
    flex: 1,
  },
});
