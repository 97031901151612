import gql from 'graphql-tag';
import color from 'color';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Image, Platform, StyleSheet, View } from 'react-native';
import { useMutation } from '@apollo/client';

import CommentItem from './CommentItem';
import {
  ActivityIndicator,
  AvatarImage,
  Divider,
  Icon,
  ListItem,
  Text,
  Title,
} from '../../components/base';
import PostLoader from '../../components/loaders/PostLoader';
import { Colors, Sizes } from '../../constants';
import { dateTime } from '../../lib';

const ARCHIVE_COMMENT = gql`
  mutation ArchiveComment($commentId: ID!) {
    archiveComment(commentId: $commentId) {
      _id
      isArchived
    }
  }
`;
const UNARCHIVE_COMMENT = gql`
  mutation UnarchiveComment($commentId: ID!) {
    unarchiveComment(commentId: $commentId) {
      _id
      isArchived
    }
  }
`;

export default function List({
  data,
  loading,
  networkStatus,
  onEditComment,
  onFetchMore,
  onPressItem,
  refetch,
  selectable,
  selectedComments,
}) {
  const post = data?.post;
  const comments = post?.comments;
  const { t } = useTranslation();

  const [archiveComment] = useMutation(ARCHIVE_COMMENT);
  const [unarchiveComment] = useMutation(UNARCHIVE_COMMENT);

  // Reverse without mutating the original array
  const mappedComments = comments?.comments.slice().map((comment) => ({
    ...comment,
    isSelected: selectedComments.find(
      (selected) => selected._id === comment._id
    ),
    selectable: selectable,
  }));

  const fetchMore = () => {
    if (comments?.nextPage && !loading) {
      onFetchMore();
    }
  };

  const onToggleArchive = (comment) => {
    if (comment.isArchived) {
      unarchiveComment({
        optimisticResponse: {
          __typename: 'Mutation',
          unarchiveComment: {
            __typename: 'Comment',
            _id: comment._id,
            isArchived: false,
          },
        },
        variables: { commentId: comment._id },
      });
    } else {
      archiveComment({
        optimisticResponse: {
          __typename: 'Mutation',
          archiveComment: {
            __typename: 'Comment',
            _id: comment._id,
            isArchived: true,
          },
        },
        variables: { commentId: comment._id },
      });
    }
  };

  return (
    <FlatList
      data={mappedComments}
      inverted={true}
      ItemSeparatorComponent={Divider}
      ListEmptyComponent={() =>
        post ? (
          <ListItem title={t('comments.no')} />
        ) : loading ? (
          <PostLoader />
        ) : null
      }
      ListFooterComponent={() => <LoadingMore networkStatus={networkStatus} />}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.5}
      onRefresh={() => refetch()}
      renderItem={({ item }) => (
        <CommentItem
          comment={item}
          onEdit={onEditComment}
          onPress={onPressItem}
          onToggleArchive={onToggleArchive}
        />
      )}
      refreshing={networkStatus === 4}
      keyExtractor={(item, index) => item._id}
    />
  );
}

const LoadingMore = ({ networkStatus }) => {
  const { t } = useTranslation();

  return networkStatus === 3 ? (
    <ListItem
      left={() => <ActivityIndicator style={styles.activity} />}
      title={t('app.loading')}
    />
  ) : null;
};

const styles = StyleSheet.create({
  activity: {
    marginRight: Sizes.unit * 2,
  },
  avatar: {
    marginRight: Sizes.unit * 4,
  },
  description: {
    color: Colors.text,
  },
  divider: {
    marginLeft: Sizes.unit * 18,
  },
  info: {
    fontSize: Sizes.font * 0.7,
  },
  list: {
    flex: 1,
  },
  user: {
    fontSize: Sizes.font,
    fontWeight: 'bold',
  },
  refreshIcon: {
    marginLeft: Sizes.unit * 3,
    marginRight: Sizes.unit * 6,
  },
});
