import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native';

const appleIcon = require('../../assets/images/apple.png');

export default function GoogleSignInButton({ children, style, ...props }) {
  const { t } = useTranslation();
  const Touchable =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
  const moreProps = Platform.select({
    android: {
      background:
        Platform.OS === 'android'
          ? TouchableNativeFeedback.Ripple('rgba(255, 255, 255, 0.3)')
          : undefined,
      useForeground: true,
    },
    default: {},
  });
  return (
    <Touchable {...props} {...moreProps}>
      <View style={[styles.content, style]}>
        <Image source={appleIcon} style={styles.icon} />
        <Text style={styles.text}>{t('auth.apple')}</Text>
      </View>
    </Touchable>
  );
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: 'black',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    borderRadius: 4,
  },
  icon: {
    marginLeft: 2,
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  text: { color: 'white', marginLeft: 10, fontSize: 16, fontWeight: 'bold' },
});
