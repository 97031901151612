import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  ImageBackground,
  Linking,
  StyleSheet,
  View,
} from 'react-native';

import { Link, Text, Title } from '../components/base';
import AuthContext from '../components/AuthContext';
import AppleSignInButton from '../components/utils/AppleSignInButton';
import GoogleSignInButton from '../components/utils/GoogleSignInButton';
import { Colors, Layout, Sizes } from '../constants';
import { auth } from '../lib';

export default function HomeScreen(props) {
  const { t } = useTranslation();
  const { signIn: contextSignIn } = React.useContext(AuthContext);
  const image = Layout.isSmallDevice
    ? 'https://res.cloudinary.com/drsg7u7zl/image/upload/c_fill,h_1920/v1593687420/Papense/home-background.png'
    : 'https://res.cloudinary.com/drsg7u7zl/image/upload/c_fill,w_1920/v1593687420/Papense/home-background.png';

  const signIn = async (method) => {
    try {
      const idToken = await auth[method]();
      if (idToken) {
        contextSignIn({ idToken: idToken });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ImageBackground source={{ uri: image }} style={styles.screen}>
      <View style={styles.card}>
        <Image
          source={require('../assets/images/logo.png')}
          style={styles.logo}
        />
        <Title style={{ marginBottom: Sizes.unit * 3 }}>
          {t('auth.signIn')}
        </Title>
        <GoogleSignInButton
          onPress={() => signIn('signInWithGoogle')}
          style={{ marginBottom: Sizes.unit * 2 }}
        />
        {/*<AppleSignInButton
          onPress={() => signIn('signInWithApple')}
          style={{ marginBottom: Sizes.unit * 3 }}
        />*/}
        <Text
          muted={true}
          style={[styles.notice, { marginBottom: Sizes.unit * 1 }]}
        >
          {t('auth.bySigningIn')}
          <Link
            onPress={() =>
              Linking.openURL('https://www.papense.com/privacy-policy')
            }
            style={[styles.notice, { textTransform: 'lowercase' }]}
          >
            {t('app.privacyPolicy')}
          </Link>
          {'.'}
        </Text>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.white,
    borderRadius: Sizes.radius,
    minWidth: Layout.isSmallDevice ? null : Sizes.unit * 120,
    margin: Layout.isSmallDevice ? null : 'auto',
    padding: Sizes.content,
  },
  logo: {
    marginBottom: Sizes.unit * 4,
    resizeMode: 'stretch',
    height: 44.6,
    width: 146.3,
  },
  notice: {
    fontSize: Sizes.font * 0.85,
  },
  safeArea: {
    flex: 1,
  },
  screen: {
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: Layout.isSmallDevice ? 'flex-end' : 'center',
    padding: Sizes.edge,
  },
});
